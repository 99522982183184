import { makeInstance } from "../utils/conectionApiConfig";
import Swal from "sweetalert2";
import Select from "react-select";
import React, { useState, useEffect } from "react";
import { useTable, usePagination } from "react-table";
import withReactContent from "sweetalert2-react-content";
import { Button, Modal, ModalBody, ModalHeader, FormGroup, ModalFooter, ButtonGroup } from "reactstrap";

import "react-datepicker/dist/react-datepicker.css";

export default function Twitter({ token }) {
  //Swal//
  const MySwal = withReactContent(Swal);
  const backEnd = makeInstance(token,'twitter');

  const [sites, setSites] = useState([]);
  const [sitesWithAccount, setSitesWithAccount] = useState([]);
  const [sitio, setSitio] = useState(null);
  const [modalProcesar, setModalProcesar] = useState(false);
  const [job, setJob] = useState(null);
  const [tratamientos, setTratamientos] = useState(null);
  const [tratamiento, setTratamiento] = useState(null);

  //ModalDetalleCuenta
  const [accountCategories, setAccountCategories] = useState([]);
  const [checkedState, setCheckedState] = useState([]);

  //Modal Agregar Cuenta - Editar Cuenta
  const [idCuenta, setIdCuenta] = useState(null);
  const [modalAgregar, setModalAgregar] = useState(false);
  const [modalEditar, setModalEditar] = useState(false);
  const [sitioTwitter, setSitioTwitter] = useState(null);
  const [tipoContenidos, setTipoContenidos] = useState([]);
  const [tipoContenido, setTipoContenido] = useState(null);
  const [sitioTwitterLabel, setSitioTwitterLabel] = useState(null);
  const [nombreCuentaTwitter, setNombreCuentaTwitter] = useState(null);
  const [consumerKey, setConsumerKey] = useState(null);
  const [consumerSecret, setConsumerSecret] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [tokenSecret, setTokenSecret] = useState(null);
  const [programado, setProgramado] = useState(false);
  const [schedule, setSchedule] = useState([]);
  const [scheduleState, setScheduleState] = useState([]);

  useEffect(() => {
    getSites();
  }, []);

  const getSitesWithAccount = () => {
    console.log("function getSitesWithAccount");
    backEnd
      .get("getSitesWithAccount")
      .then(({ data }) => {
        setSitesWithAccount(data);
      })
      .catch((err) => {
        console.log("Error:", err);
      });
  };

  const getSites = () => {
    console.log("function getSites");
    backEnd
      .get("getSites")
      .then(({ data }) => {
        setSites(data);
      })
      .catch((err) => {
        console.log("Error:", err);
      })
      .then(() => {
        getSitesWithAccount();
        getTratamientos();
      });
  };

  const getTratamientos = () => {
    console.log("function getTratamientos");
    backEnd
      .get("getTratamiento")
      .then(({ data }) => {
        setTratamientos(data);
      })
      .catch((err) => {
        console.log(err);
      })
      .then(() => console.log("Tratamientos Obtenidos"));
  };

  const findAccounts = (site) => {
    console.log("function findAccounts", site);
    backEnd
      .get("getJobs/" + site)
      .then(({ data }) => {
        console.log(data);
        setBody(data);
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  const getJobDetail = (idAccount) => {
    console.log("function getJobDetail");
    backEnd
      .get("getJobData/" + idAccount)
      .then(({ data }) => {
        console.log(data);
        setJob(data);
        setTratamiento(data.idTratamiento);
        setTipoContenido(data.idTipoContenido);
        setAccountCategories(data.detail);
        setCheckedState_(data.detail);
        setProgramado(data.programado);
        getTipoContenido();
        procesaAgenda(data.schedule);
      })
      .catch((err) => console.log("Error: ", err.response))
      .then(() => {
        setModalProcesar(true);
      });
  };

  const getTipoContenido = () => {
    console.log("function getContenido");
    backEnd
      .get("getTipoContenido")
      .then(({ data }) => {
        console.log(data);
        setTipoContenidos(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setCheckedState_ = (lista) => {
    console.log("function setCheckedState_");
    var result = [];
    lista.map((item, index) => {
      console.log(index, item);
      result.push(item.status === 1 ? true : false);
    });
    setCheckedState(result);
  };

  const submitAccount = (event) => {
    event.preventDefault();
    console.log("function submitAccout");
  };

  const agregaTwitter = (event) => {
    event.preventDefault();
    console.log("function agregaTwitter");
    backEnd
      .post("addTwitter", { idSite: sitioTwitter, accountName: nombreCuentaTwitter, accountConsumerKey: consumerKey, accountConsumerSecret: consumerSecret, accountAccessToken: accessToken, accountTokenSecret: tokenSecret, accountStatus: 1 })
      .then(({ data }) => {
        setModalAgregar(false);
        return MySwal.fire(<p>{data.message}</p>);
      })
      .catch((err) => {
        console.log("Error:", err.response.data.message);
        return MySwal.fire(<p>{err.response.data.message}</p>);
      })
      .then(() => console.log("Cuenta Agregada"));
  };

  const procesaAgenda = (input) => {
    console.log("function procesaAgenda ", input);
    setSchedule(input);
    let result = [];
    input.map((item) => {
      result.push(item.status);
    });
    setScheduleState(result);
  };

  const scheduleUpdate = (position, day, checked) => {
    let update = { idJob: job.idJob, day: day, checked: checked ? 1 : 0 };
    backEnd
      .post("updateSchedule", update)
      .then(() => {
        const updatedCheckedState = scheduleState.map((item, index) => (index === position ? !item : item));
        setScheduleState(updatedCheckedState);
      })
      .catch((err) => {
        console.log("Error en la actualizacion de Agenda", err.response);
      });
  };

  const updateProgramation = (input) => {
    setProgramado(input);
    let data = { idJob: job.idJob, update: { programado: input } };
    backEnd
      .post("setProgramation", data)
      .then(() => {
        console.log(data);
      })
      .catch((err) => {
        console.log("Error en la actualizacion de Tratamiento", err.response);
      });
  };

  const procesaTipoContenido = (input) => {
    console.log("function procesaTipoContenido");
    setTipoContenido(input);
    backEnd
      .post("setTipoContenido", { idJob: job.idJob, update: { idTipoContenido: input } })
      .then(({ data }) => {
        console.log(data);
      })
      .catch((err) => {
        console.log("Error:", err);
      })
      .then(() => {
        console.log("Tipo de contenido Actualizado");
        jobReload();
      });
  };

  const procesaTratamiento = (input) => {
    console.log("function procesaTratamiento", input);
    setTratamiento(input);
    backEnd
      .post("setProcesamiento", { idJob: job.idJob, update: { idAction: input } })
      .then(({ data }) => {
        console.log(data);
      })
      .catch((err) => console.log("Error:", err))
      .then(() => {
        console.log("Tratamiento Actualizado");
        jobReload();
      });
  };

  const procesaCheckbox = (props) => {
    console.log("function procesaCheckbox", props.target.checked, props.target.value, job.idJob);
    backEnd
      .post("setJobDetail", { idJob: job.idJob, nameCategory: props.target.value, status: props.target.checked ? 1 : 0 })
      .then(({ data }) => {
        console.log(data);
      })
      .catch((err) => console.log(err))
      .then(() => {
        console.log("Proceso Terminado");
      });
  };

  const updateCheckedState = (position) => {
    const updatedCheckedState = checkedState.map((item, index) => (index === position ? !item : item));
    setCheckedState(updatedCheckedState);
  };

  const jobReload = () => {
    console.log("function jobReload");
    backEnd
      .get("getJobs/" + sitio)
      .then(({ data }) => {
        setBody(data);
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  const getAccount = (idAccount) => {
    console.log("function getAccount");
    backEnd
      .get("getAccountData/" + idAccount)
      .then(({ data }) => {
        setIdCuenta(data.idAccount);
        setSitioTwitter(data.idSite);
        setSitioTwitterLabel(data.description);
        setNombreCuentaTwitter(data.accountName);
        setConsumerKey(data.accountConsumerKey);
        setConsumerSecret(data.accountConsumerSecret);
        setAccessToken(data.accountAccessToken);
        setTokenSecret(data.accountTokenSecret);
        console.log(data);
      })
      .catch((err) => console.log("Error: ", err))
      .then(() => {
        setModalEditar(true);
      });
  };

  const editarAccount = () => {
    console.log("function editarAccount");
    var input = { idAccount: idCuenta, update: { idSite: sitioTwitter, accountName: nombreCuentaTwitter, accountConsumerKey: consumerKey, accountConsumerSecret: consumerSecret, accountAccessToken: accessToken, accountTokenSecret: tokenSecret } };
    backEnd
      .post("setAccount", input)
      .then(({ data }) => {
        if (data) {
          Swal.fire("Cuenta Actualizada", "", "success");
          setModalEditar(false);
        } else {
          Swal.fire("Error en la actualizacion de cuenta", "", "error");
        }
      })
      .catch((err) => {
        console.log("Err:", err);
      })
      .then(() => {
        jobReload();
      });
  };

  //Tabla//
  const header = [
    {
      Header: "Nombre",
      accessor: "accountName",
    },
    {
      Header: "Tipo Contenido",
      accessor: "tipoContenido",
      Cell: ({ value }) => (value == null ? "Sin configurar" : value),
    },
    {
      Header: "Tratamiento",
      accessor: "tratamiento",
      Cell: ({ value }) => (value == null ? "Sin configurar" : value),
    },
    {
      Header: "Acciones",
      accessor: "acciones",
      Cell: ({ value }) => (
        <div className="buttonGroup">
          <button
            className="btn see"
            size="sm"
            onClick={() => {
              getJobDetail(value);
            }}
          >
            <i className="fas fa-pencil-alt"></i>
            Configurar
          </button>
          <button
            className="btn edit"
            size="sm"
            onClick={() => {
              console.log("Modifica Cuenta");
              getAccount(value);
            }}
          >
            <i className="fas fa-pencil-alt"></i>
            Editar
          </button>
          <button
            className="btn danger"
            size="sm"
            onClick={() => {
              Swal.fire({
                title: "Estas seguro",
                text: `Esta seguro que quiere eliminar la Cuenta?`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Eliminar Cuenta",
              }).then((result) => {
                if (result.isConfirmed) {
                  Swal.fire("Cuenta Eliminada", "", "success");
                  backEnd
                    .post("/deleteAccount/" + value)
                    .then((data) => {
                      console.log(data);
                    })
                    .catch((err) => {
                      console.log(err);
                    })
                    .then(() => {
                      jobReload();
                    });
                }
              });
            }}
          >
            <i className="fas fa-pencil-alt"></i>
            Eliminar
          </button>
        </div>
      ),
    },
  ];
  const [body, setBody] = useState([]);

  const GeneraTabla = () => {
    const data = React.useMemo(() => body, []);
    const columns = React.useMemo(() => header, []);

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      // rows,
      prepareRow,
      page, // Instead of using 'rows', we'll use page,
      // which has only the rows for the active page

      // The rest of these things are super handy, too ;)
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns,
        data,
        initialState: { pageIndex: 0 },
      },
      usePagination
    );

    return (
      <>
        <table {...getTableProps()} className="table table-striped table-hover">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="pagination">
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {"<<"}
          </button>{" "}
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {"<"}
          </button>{" "}
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            {">"}
          </button>{" "}
          <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            {">>"}
          </button>{" "}
          <span className="numeroActualPage">
            Página{" "}
            <strong>
              {pageIndex + 1} de {pageOptions.length}
            </strong>{" "}
          </span>
          <span className="numeroGoPage">
            Ir a la página:{" "}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "100px" }}
            />
          </span>{" "}
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            placeholder={"Seleccione"}
          >
            {[10, 20, 50, 200, 500, 3000].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Mostrar {pageSize}
              </option>
            ))}
          </select>
        </div>
      </>
    );
  };

  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid #279655",
      "&:hover": {
        border: "1px solid #279655!important",
      },
      boxShadow: "none",
    }),
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="box">
              <div className="box__header">
                <h3>Twitter</h3>
                <button
                  onClick={() => {
                    setModalAgregar(true);
                  }}
                  className="btn newUser"
                >
                  <i className="fas fa-user-plus"></i>
                  Agregar Cuenta
                </button>
              </div>
              <div className="box__body">
                <div className="form-group">
                  <Select
                    placeholder={"Seleccione un sitio"}
                    styles={style}
                    options={sitesWithAccount}
                    onChange={(seleccion) => {
                      setSitio(seleccion.value);
                      findAccounts(seleccion.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mb-5">
        <div className="row">
          <div className="col-12">
            <div className="box">
              <div className="box__header">
                <h3>Resultado</h3>
              </div>
              <div className="box__body">{body.length > 0 ? <GeneraTabla /> : null}</div>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={modalProcesar} size="xl">
        <form onSubmit={submitAccount}>
          <ModalHeader>Detalle de la Cuenta</ModalHeader>
          <ModalBody>
            <FormGroup>
              <label>Nombre de Cuenta</label>
              <input type="text" className="form-control" value={modalProcesar ? job.accountName : null} readOnly={true} />
            </FormGroup>
            <FormGroup>
              <label>Tipo Contenido</label>
              <Select
                placeholder="Selecciona un tipo de contenido"
                styles={style}
                defaultValue={tipoContenido ? { value: tipoContenido, label: job.tipoContenido } : null}
                options={tipoContenidos}
                onChange={(seleccion) => {
                  procesaTipoContenido(seleccion.value);
                }}
              />
            </FormGroup>
            <FormGroup>
              <label>Programacion</label>
              <Select
                styles={style}
                defaultValue={programado ? { value: 1, label: "Personalizado" } : { value: 0, label: "Todos los Dias" }}
                options={[
                  { value: 0, label: "Todos los Dias" },
                  { value: 1, label: "Personalizado" },
                ]}
                onChange={(seleccion) => {
                  updateProgramation(seleccion.value);
                }}
              />
            </FormGroup>
            <FormGroup>
              <div hidden={!programado}>
                <label>Detalle</label>
                <div className="row">
                  <div className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                    {schedule.map((day, index) => (
                      <>
                        <input
                          type="checkbox"
                          className="btn-check"
                          id={"btncheckweek" + index}
                          checked={scheduleState[index]}
                          value={day.dia}
                          onChange={({ target }) => {
                            scheduleUpdate(index, target.value, target.checked);
                          }}
                        />
                        <label className="btn btn-outline-success" htmlFor={"btncheckweek" + index}>
                          <i className="fas fa-globe"></i> {day.dia}
                        </label>
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </FormGroup>
            <FormGroup>
              <label>Tratamiento</label>
              <Select
                placeholder={"Seleccione un tratamiento"}
                styles={style}
                defaultValue={{ value: job ? job.idTratamiento : null, label: job ? job.tratamiento : null }}
                options={tratamientos}
                onChange={(seleccion) => {
                  procesaTratamiento(seleccion.value);
                }}
              />
            </FormGroup>
            <div hidden={tratamiento === 3 ? false : true}>
              <label>Categorias</label>
              {accountCategories.map((cat, index) => (
                <FormGroup>
                  <div className="row">
                    <div className="btn-group col-md-4" role="group" aria-label="Basic checkbox toggle button group">
                      <input
                        type="checkbox"
                        className="btn-check"
                        id={"btncheck" + index}
                        autoComplete="off"
                        value={accountCategories[index].category}
                        checked={checkedState[index]}
                        onChange={(props) => {
                          updateCheckedState(index);
                          procesaCheckbox(props);
                        }}
                      />
                      <label className="btn btn-outline-success" htmlFor={"btncheck" + index}>
                        <i className="far fa-clock"></i> {accountCategories[index].category}
                      </label>
                    </div>
                  </div>
                </FormGroup>
              ))}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              onClick={() => {
                setModalProcesar(false);
              }}
            >
              Cerrar
            </Button>
          </ModalFooter>
        </form>
      </Modal>
      <Modal isOpen={modalAgregar} size="xl">
        <form onSubmit={agregaTwitter}>
          <ModalHeader>Agregar cuanta de Twitter</ModalHeader>
          <ModalBody>
            <FormGroup>
              <label>Sitio</label>
              <Select
                placeholder={"Seleccione un sitio"}
                styles={style}
                options={sites}
                required={true}
                onChange={(seleccion) => {
                  setSitioTwitter(seleccion.value);
                }}
              />
            </FormGroup>
            <FormGroup>
              <label>Nombre de Cuenta</label>
              <input
                type="text"
                className="form-control"
                required={true}
                onChange={({ target }) => {
                  setNombreCuentaTwitter(target.value);
                }}
              />
            </FormGroup>
            <FormGroup>
              <label>ConsumerKey</label>
              <input
                type="text"
                className="form-control"
                required={true}
                onChange={({ target }) => {
                  setConsumerKey(target.value);
                }}
                placeholder=""
              />
            </FormGroup>
            <FormGroup>
              <label>ConsumerSecret</label>
              <input
                type="text"
                className="form-control"
                required={true}
                onChange={({ target }) => {
                  setConsumerSecret(target.value);
                }}
                placeholder=""
              />
            </FormGroup>
            <FormGroup>
              <label>AccessToken</label>
              <input
                type="text"
                className="form-control"
                required={true}
                onChange={({ target }) => {
                  setAccessToken(target.value);
                }}
                placeholder=""
              />
            </FormGroup>
            <FormGroup>
              <label>TokenSecret</label>
              <input
                type="text"
                className="form-control"
                required={true}
                onChange={({ target }) => {
                  setTokenSecret(target.value);
                }}
                placeholder=""
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <button className="btn btn-success" type="submit">
                Guardar
              </button>
              <button
                className="btn btn-danger"
                type="button"
                onClick={() => {
                  setModalAgregar(false);
                }}
              >
                Cerrar
              </button>
            </ButtonGroup>
          </ModalFooter>
        </form>
      </Modal>
      <Modal isOpen={modalEditar} size="xl">
        <form onSubmit={editarAccount}>
          <ModalHeader>Editar Cuenta</ModalHeader>
          <ModalBody>
            <FormGroup>
              <label>Sitio</label>
              <Select
                placeholder={"Seleccione un sitio"}
                defaultValue={sitioTwitter ? { value: sitioTwitter, label: sitioTwitterLabel } : null}
                styles={style}
                options={sites}
                required={true}
                onChange={(seleccion) => {
                  setSitioTwitter(seleccion.value);
                }}
              />
            </FormGroup>
            <FormGroup>
              <label>Nombre de Cuenta</label>
              <input
                type="text"
                className="form-control"
                required={true}
                value={nombreCuentaTwitter}
                onChange={({ target }) => {
                  setNombreCuentaTwitter(target.value);
                }}
              />
            </FormGroup>
            <FormGroup>
              <label>ConsumerKey</label>
              <input
                type="text"
                className="form-control"
                required={true}
                value={consumerKey}
                onChange={({ target }) => {
                  setConsumerKey(target.value);
                }}
                placeholder=""
              />
            </FormGroup>
            <FormGroup>
              <label>ConsumerSecret</label>
              <input
                type="text"
                className="form-control"
                required={true}
                value={consumerSecret}
                onChange={({ target }) => {
                  setConsumerSecret(target.value);
                }}
                placeholder=""
              />
            </FormGroup>
            <FormGroup>
              <label>AccessToken</label>
              <input
                type="text"
                className="form-control"
                required={true}
                value={accessToken}
                onChange={({ target }) => {
                  setAccessToken(target.value);
                }}
                placeholder=""
              />
            </FormGroup>
            <FormGroup>
              <label>TokenSecret</label>
              <input
                type="text"
                className="form-control"
                required={true}
                value={tokenSecret}
                onChange={({ target }) => {
                  setTokenSecret(target.value);
                }}
                placeholder=""
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <button
                className="btn btn-success"
                type="button"
                onClick={() => {
                  Swal.fire({
                    title: "Estas seguro",
                    text: `Esta seguro que quiere actualizar la Cuenta?`,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Actualizar Cuenta",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      editarAccount();
                    }
                  });
                }}
              >
                Guardar
              </button>
              <button
                className="btn btn-danger"
                type="button"
                onClick={() => {
                  setModalEditar(false);
                }}
              >
                Cerrar
              </button>
            </ButtonGroup>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
}
