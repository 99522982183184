export default function CheckLogin() {
  var data = window.localStorage.getItem("sessionDash");

  if (data === null) {
    return { status: false };
  }
  if (data !== "undefined") {
    let session = JSON.parse(data);
    return session;
  }
  return { status: false };
}
