import axios from "axios";
import Select from "react-select";
import React, { useState, useEffect } from "react";
import { Button, Table } from "react-bootstrap";


export default function PushReporte({ token }) {
  const [sitio, setSitio] = useState("");
  const [reporte, setReporte] = useState(null);
  const d = new Date();
  //Combos
  const [comboSitios, setComboSitio] = useState([{ value: "", label: "2Todos" }]);
  const [comboSitiosStatus, setComboSitioStatus] = useState(true);
  const [defaultSite, setDefaultSite] = useState({ value: "", label: "Todos" });


  // Produccion Server //
  const instance = axios.create({
    baseURL: "https://data.metroworldnews.com/api",
    headers: { Authorization: token },
  });


  const obtieneSitios = async () => {
    var result = null;
    instance
      // .post("/reportes/getSitios")
      .get("permisos/getSitios")
      .then((data) => {
        result = data.data;
      })
      .catch((err) => {
        console.log("Error en obtencion de Sitios");
        console.log(err.response);
      })
      .then(() => {
        var sitios = [];
        if (result.length > 0) {
          for (var x = 0; x < result.length; x++) {
            var elemento = result[x];
            sitios.push({ value: elemento._id, label: elemento.name });

          }
          setComboSitio(sitios);

          setSitio({ value: sitios[0].value, label: sitios[0].label })
          setComboSitioStatus(false);
          setDefaultSite({ value: sitios[0].value, label: sitios[0].label })
          getReporte(sitios[0].value, 0);

        } else {
          console.log("Problemas con la obtencion de Sitios");
        }
      });
  };

  const getReporte = async (_site = false, params) => {

    var _result = null;
    let site = (sitio ? sitio.value : _site);
    let param = params;
    let timeZone = d.getTimezoneOffset()

    var tbodyRef = document.getElementById('table_report').getElementsByTagName('tbody')[0];
    tbodyRef.innerHTML = '';

    var tbodyResumenRef = document.getElementById('table_resumen').getElementsByTagName('tbody')[0];
    tbodyResumenRef.innerHTML = '';


    axios
      .get("https://pdfserv2.readmetro.com/reportesPush/reporte.php", {
        params: {
          site,
          param,
          timeZone
        }
      })
      .then((data) => {
        _result = data;
      })
      .catch((err) => {
        console.log("Error en obtencion de Sitios");
        console.log(err.response);
      })
      .then(() => {

        if (_result.data != '') {


        
          for (var x = 0; x < _result.data.data.length; x++) {
            //console.log(_result.data.totalDays[0]);
            // Create row element
            let row = document.createElement("tr")

            // Create cells
            let c1 = document.createElement("td")
            let c2 = document.createElement("td")
            let c3 = document.createElement("td")
            let c4 = document.createElement("td")

            var link = document.createElement("a");
            link.setAttribute("href", _result.data.data[x].url)

            var linkText = document.createTextNode(_result.data.data[x].url);
            link.appendChild(linkText);

            let dateObj = new Date(_result.data.data[x].fecha)

            let yyyy = dateObj.getFullYear();
            let mm = dateObj.getMonth() + 1; // Months start at 0!
            let dd = dateObj.getDate();

            let hr = dateObj.getHours();
            let min = dateObj.getMinutes();

            if (dd < 10) dd = '0' + dd;
            if (mm < 10) mm = '0' + mm;

            if (hr < 10) hr = '0' + hr;
            if (min < 10) min = '0' + min;

            // Insert data to cells
            c1.innerText = _result.data.data[x].titulo
            c2.appendChild(link)
            c3.innerText = dd + '/' + mm + '/' + yyyy + ' ' + hr + ':' + min;
            c4.innerText = _result.data.data[x].tipo

            //links


            // Add the link to the previously created TableCell.



            // Append cells to row
            row.appendChild(c1);
            row.appendChild(c2);
            row.appendChild(c4);
            row.appendChild(c3);


            // Append row to table body
            tbodyRef.appendChild(row)
            // document.getElementById('totalDays').innerHTML = _result.data.totalDays[0] + ' Push en arc';

          }


          for (var x = 0; x < _result.data.totalDays.length; x++) {
            //console.log(_result.data.totalDays[0]);
            // Create row element
            let row = document.createElement("tr")

            // Create cells
            let c1 = document.createElement("td")
            let c2 = document.createElement("td")


            let dateObj = new Date(_result.data.totalDays[x].days)

            let yyyy = dateObj.getFullYear();
            let mm = dateObj.getMonth() + 1; // Months start at 0!
            let dd = dateObj.getDate();

            if (dd < 10) dd = '0' + dd;
            if (mm < 10) mm = '0' + mm;

            // Insert data to cells
            c1.innerText = dd + '/' + mm + '/' + yyyy;
            c2.innerText = _result.data.totalDays[x].total


            row.appendChild(c1);
            row.appendChild(c2);

            // Append row to table body
            tbodyResumenRef.appendChild(row)


          }



        } else {
          console.log("Problemas con la obtencion de Sitios");
        }
      });
  };


  useEffect(() => {
    obtieneSitios();
  }, []);

  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid #279655",
      "&:hover": {
        border: "1px solid #279655!important",
      },
      boxShadow: "none",
    }),
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="box">
              <div className="box__header">
                <h3>Reportes Push ARC</h3>
              </div>
              <div className="box__body">
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-6">
                      <Select
                        value={sitio ? sitio : ''}
                        options={comboSitios}
                        onChange={(sitio) => {
                          setSitio({ value: sitio.value, label: sitio.label });

                        }}
                        isDisabled={comboSitiosStatus}
                        isSearchable={false}
                        styles={style}
                      />
                    </div>
                    <div className="col-md-2">
                      <Button color="success" onClick={() => getReporte(false, 0)}>Hoy</Button>{' '}
                    </div>
                    <div className="col-md-2">
                      <Button color="success" onClick={() => getReporte(false, 10)}>10 Ultimos Días</Button>{' '}
                    </div>
                    {/* <div className="col-md-2">
                    <Button color="success" onClick={() => getReporte(false,10)}>Resumen</Button>{' '}
                    </div>   */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mb-5">
        <div className="row">
          <div className="col-12">
            <div className="box">
              <div className="box__header">
                <h3>Resumen últimos 10 días</h3>

              </div>
              <div className="box__body">
                <Table striped id={'table_resumen'}>
                  <thead>
                    <tr>
                      <th>Días</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mb-5">
        <div className="row">
          <div className="col-12">
            <div className="box">
              <div className="box__header">
                <h3>Resultado</h3>

              </div>
              <div className="box__body">
                <Table striped id={'table_report'}>
                  <thead>
                    <tr>
                      <th>Titulo</th>
                      <th>Url</th>
                      <th>Tipo</th>
                      <th>Fecha</th>
                    </tr>
                  </thead>
                  <tbody>

                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
