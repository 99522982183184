import Select from "react-select";
import DatePicker from "react-datepicker";
// import CheckLogin from "../hooks/checkLogin";
import React, { useState, useEffect } from "react";
import { useTable, usePagination } from "react-table";

import "react-datepicker/dist/react-datepicker.css";
import { makeInstance } from "../utils/conectionApiConfig";

export default function ReportClonado({ token }) {
  //VALORES//
  const [sitio, setSitio] = useState(null);
  const [sitiosCompartidos, setSitiosCompartidos] = useState(null);
  const [fecha, setFecha] = useState(null);
  const [showPageViews, setShowPageViews] = useState(false);

  //TABLA//
  const header = [
    {
      Header: "Clonados",
      accessor: "duplicados",
      Cell: (e) => (
        <p>
          {e.value.mwn.class !== "ROJO" ? (
            <a href={e.value.mwn.urlArticulo} target="_blank" rel="noreferrer" className={e.value.mwn.class}>
              mwn
            </a>
          ) : (
            <span className={e.value.mwn.class}>mwn</span>
          )}
          {e.value.mwnchile.class !== "ROJO" ? (
            <a href={e.value.mwnchile.urlArticulo} target="_blank" rel="noreferrer" className={e.value.mwnchile.class}>
              mwnchile
            </a>
          ) : (
            <span className={e.value.mwnchile.class}>mwnchile</span>
          )}
          {e.value.mwnmexico.class !== "ROJO" ? (
            <a href={e.value.mwnmexico.urlArticulo} target="_blank" rel="noreferrer" className={e.value.mwnmexico.class}>
              mwnmexico
            </a>
          ) : (
            <span className={e.value.mwnmexico.class}>mwnmexico</span>
          )}
          {e.value.mwncolombia.class !== "ROJO" ? (
            <a href={e.value.mwncolombia.urlArticulo} target="_blank" rel="noreferrer" className={e.value.mwncolombia.class}>
              mwncolombia
            </a>
          ) : (
            <span className={e.value.mwncolombia.class}>mwncolombia</span>
          )}
          {e.value.mwnecuador.class !== "ROJO" ? (
            <a href={e.value.mwnecuador.urlArticulo} target="_blank" rel="noreferrer" className={e.value.mwnecuador.class}>
              mwnecuador
            </a>
          ) : (
            <span className={e.value.mwnecuador.class}>mwnecuador</span>
          )}
          {e.value.mwnperu.class !== "ROJO" ? (
            <a href={e.value.mwnperu.urlArticulo} target="_blank" rel="noreferrer" className={e.value.mwnperu.class}>
              mwnperu
            </a>
          ) : (
            <span className={e.value.mwnperu.class}>mwnperu</span>
          )}
          <br />
          {e.value.ferplei.class !== "ROJO" ? (
            <a href={e.value.ferplei.urlArticulo} target="_blank" rel="noreferrer" className={e.value.ferplei.class}>
              ferplei
            </a>
          ) : (
            <span className={e.value.ferplei.class}>ferplei</span>
          )}
          {e.value.fayerwayer.class !== "ROJO" ? (
            <a href={e.value.fayerwayer.urlArticulo} target="_blank" rel="noreferrer" className={e.value.fayerwayer.class}>
              fayerwayer
            </a>
          ) : (
            <span className={e.value.fayerwayer.class}>fayerwayer</span>
          )}
          {e.value.nuevamujer.class !== "ROJO" ? (
            <a href={e.value.nuevamujer.urlArticulo} target="_blank" rel="noreferrer" className={e.value.nuevamujer.class}>
              nuevamujer
            </a>
          ) : (
            <span className={e.value.nuevamujer.class}>nuevamujer</span>
          )}
          {e.value.mwnbrasil.class !== "ROJO" ? (
            <a href={e.value.mwnbrasil.urlArticulo} target="_blank" rel="noreferrer" className={e.value.mwnbrasil.class}>
              mwnbrasil
            </a>
          ) : (
            <span className={e.value.mwnbrasil.class}>mwnbrasil</span>
          )}
          {e.value.novamulher.class !== "ROJO" ? (
            <a href={e.value.novamulher.urlArticulo} target="_blank" rel="noreferrer" className={e.value.novamulher.class}>
              novamulher
            </a>
          ) : (
            <span className={e.value.novamulher.class}>novamulher</span>
          )}
          {e.value.comutricolor.class !== "ROJO" ? (
            <a href={e.value.comutricolor.urlArticulo} target="_blank" rel="noreferrer" className={e.value.comutricolor.class}>
              comutricolor
            </a>
          ) : (
            <span className={e.value.comutricolor.class}>comutricolor</span>
          )}
          <br />
          {e.value.mwnpuertorico.class !== "ROJO" ? (
            <a href={e.value.mwnpuertorico.urlArticulo} target="_blank" rel="noreferrer" className={e.value.mwnpuertorico.class}>
              mwnpuertorico
            </a>
          ) : (
            <span className={e.value.mwnpuertorico.class}>mwnpuertorico</span>
          )}
          {e.value.elcalce.class !== "ROJO" ? (
            <a href={e.value.elcalce.urlArticulo} target="_blank" rel="noreferrer" className={e.value.elcalce.class}>
              elcalce
            </a>
          ) : (
            <span className={e.value.elcalce.class}>elcalce</span>
          )}
          {e.value.sabrosia.class !== "ROJO" ? (
            <a href={e.value.sabrosia.urlArticulo} target="_blank" rel="noreferrer" className={e.value.sabrosia.class}>
              sabrosia
            </a>
          ) : (
            <span className={e.value.sabrosia.class}>sabrosia</span>
          )}
          {e.value.activo.class !== "ROJO" ? (
            <a href={e.value.activo.urlArticulo} target="_blank" rel="noreferrer" className={e.value.activo.class}>
              activo
            </a>
          ) : (
            <span className={e.value.activo.class}>activo</span>
          )}
          {e.value.mwnargentina.class !== "ROJO" ? (
            <a href={e.value.mwnargentina.urlArticulo} target="_blank" rel="noreferrer" className={e.value.mwnargentina.class}>
              mwnargentina
            </a>
          ) : (
            <span className={e.value.mwnargentina.class}>mwnargentina</span>
          )}
        </p>
      ),
    },
    { Header: "URL", accessor: "url" },
    { Header: "Fecha", accessor: "fecha" },
    { Header: "Visitas", accessor: "pageViews" },
    {
      Header: "Enlace ARC",
      accessor: "arcLink",
      Cell: (e) => (
        <a href={e.value} target="_blank" rel="noreferrer" className="btn">
          <i className="fas fa-external-link-alt"></i>ir a Arc
        </a>
      ),
    },
  ];
  const [body, setBody] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  //CARGAS//
  const [comboSitios, setComboSitios] = useState([]);
  const [comboSitiosHasta, setComboSitiosHasta] = useState([]);

  const instance = makeInstance(token);

  //FUNCIONES//
  const getSitios = () => {
    var result = null;
    instance
      // .post("/reportes/getSitios_")
      .get("permisos/getSitios_")
      .then((data) => {
        result = data.data;
      })
      .catch((err) => {
        var response = err.response.data;
        console.log(response);
      })
      .then(() => {
        var sitiosDesde = [];
        var sitiosHasta = [
          { value: "todos", label: "Todos" },
          { value: "ninguno", label: "Ninguno" },
        ];
        if (result != null) {
          if (result.length > 0) {
            for (var x = 0; x < result.length; x++) {
              var elemento = result[x];
              sitiosDesde.push({ value: elemento._id, label: elemento.name });
              sitiosHasta.push({ value: elemento._id, label: elemento.name });
            }
          }
          setComboSitios(sitiosDesde);
          setComboSitiosHasta(sitiosHasta);
        }
      });
  };

  const getReporte = () => {
    setIsLoading(true);
    setBody([]);
    var response = {};
    response.filtro = {
      sitio: sitio,
      sitioCompartidos: sitiosCompartidos,
      fecha: fecha,
      pageViews: showPageViews,
    };
    instance
      .post("/reportes/getReporteClonado", response)
      .then((data) => {
        setBody(data.data.data);
      })
      .catch((err) => {
        var response = err.response.data;
        console.log(response);
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  const changeShowPageViews = () => {
    setShowPageViews(!showPageViews);
  };

  const GeneraTabla = () => {
    const data = React.useMemo(() => body, []);
    const columns = React.useMemo(() => header, []);

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      // rows,
      prepareRow,
      page, // Instead of using 'rows', we'll use page,
      // which has only the rows for the active page

      // The rest of these things are super handy, too ;)
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns,
        data,
        initialState: { pageIndex: 0 },
      },
      usePagination
    );

    return (
      <>
        <table {...getTableProps()} className="table table-striped table-hover">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="pagination">
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {"<<"}
          </button>{" "}
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {"<"}
          </button>{" "}
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            {">"}
          </button>{" "}
          <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            {">>"}
          </button>{" "}
          <span className="numeroActualPage">
            Página{" "}
            <strong>
              {pageIndex + 1} de {pageOptions.length}
            </strong>{" "}
          </span>
          <span className="numeroGoPage">
            Ir a la página:{" "}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "100px" }}
            />
          </span>{" "}
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            placeholder={"Seleccione"}
          >
            {[10, 20, 50, 200, 500, 3000].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Mostrar {pageSize}
              </option>
            ))}
          </select>
        </div>
      </>
    );
  };

  //CARGAS INICIALES//
  useEffect(() => {
    getSitios();
  }, []);

  //STYLE//
  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid #279655",
      "&:hover": {
        border: "1px solid #279655!important",
      },
      boxShadow: "none",
    }),
  };

  //EXTERNOS//

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="box">
              <div className="box__header">
                <h3>Filtros de Sitio</h3>
              </div>
              <div className="box__body">
                <div className="form-group">
                  <div className="row justify-content-md-center">
                    <div className="col-md-3">
                      <Select
                        value={sitio ? comboSitios.value : "todos"}
                        options={comboSitios}
                        onChange={(sitio) => {
                          setSitio(sitio.value);
                        }}
                        isSearchable={false}
                        placeholder={"Sitio Origen"}
                        styles={style}
                      />
                    </div>
                    <div className="col-md-3">
                      <Select
                        value={sitiosCompartidos ? comboSitiosHasta.value : "todos"}
                        options={comboSitiosHasta}
                        onChange={(sitio) => {
                          setSitiosCompartidos(sitio.value);
                        }}
                        isSearchable={false}
                        placeholder={"Compartidos en"}
                        styles={style}
                      />
                    </div>
                    <div className="col-md-3">
                      <DatePicker
                        className="form-control"
                        selected={fecha}
                        onChange={(fecha) => {
                          setFecha(fecha);
                        }}
                        placeholderText="Seleccione fecha"
                        dateFormat="dd/MM/yyyy"
                        styles={style}
                      />
                    </div>
                  </div>
                  <br />
                  <h5>Opciones de Consulta</h5>
                  <div className="row">
                    <div className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                      <input type="checkbox" className="btn-check" id="btncheck1" checked={showPageViews} onChange={changeShowPageViews} />
                      <label className="btn btn-outline-success" htmlFor="btncheck1">
                        <i className="fas fa-globe"></i> PageViews
                      </label>
                    </div>
                  </div>
                  <div className="col-12 text-center my-3">
                    <button className="btn me-md-2" onClick={getReporte}>
                      Enviar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mb-5">
        <div className="row">
          <div className="col-12">
            <div className="box">
              <div className="box__header">
                <h3>Resultado</h3>
              </div>
              <div className="box__body">
                <div className="alert alert-success" role="alert">
                  {isLoading ? (
                    <>
                      <div className="modales"></div>
                      <div className="loader"></div>
                    </>
                  ) : (
                    <GeneraTabla />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
