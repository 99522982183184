//import { GoogleLogin, GoogleLogout } from "react-google-login";
import { GoogleLogin} from '@react-oauth/google';
import jwt_decode from "jwt-decode";

import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export default function Session(props) {
  const url = process.env.REACT_APP_MODE === 'local' ? 'http://localhost:3001/api/log/in' : "https://data.metroworldnews.com/api/log/in";
  const MySwal = withReactContent(Swal);
  var { session } = props;

  const responseGoogle = async (response) => {
    //console.log("call",response);
    //var data = { token: response.tokenId };
    var data = { token: response.credential };
      //console.log("data",data);
    axios
      .post(url, data)
      .then(({ data }) => {
        //console.log("then" , data);
        window.localStorage.setItem("sessionDash", JSON.stringify(data));
        window.location.reload();
      })
      .catch(({ response }) => {
        //console.log("ERR-->", response.data.message);
        MySwal.fire({
          title: <p>Dash metroworldnews</p>,
          footer: "Copyright 2022",
          confirmButtonColor: "#3085d6",
          didOpen: () => {
            MySwal.clickConfirm();
          },
        }).then(() => {
          return MySwal.fire(<p>{response.data.message}</p>);
        });
      });
  };

  const logOut = () => {
    window.localStorage.setItem("sessionDash", JSON.stringify(undefined));
    window.location.reload();
  };

  return (
    <>
      {!session.status ? (
        // <GoogleLogin clientId="846804145066-8esoa5sal1n9rhkpjngf10kaq4fvvic3.apps.googleusercontent.com" buttonText="1 Login" onSuccess={responseGoogle} onFailure={responseGoogle} cookiePolicy={"single_host_origin"} />
        <GoogleLogin
          onSuccess={credentialResponse => {
            // console.log("1",credentialResponse);
            // console.log("2",credentialResponse.credential);
            // console.log("3",jwt_decode(credentialResponse.credential));
            responseGoogle(credentialResponse);
          }}
          size="small"
          shape="square"

          onError={() => {
            console.log('Login Failed');
          }}
        />      ) : (
          <button onClick={logOut}>
          <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg"><g fill="#000" fill-rule="evenodd"><path d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z" fill="#EA4335"></path><path d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z" fill="#4285F4"></path><path d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z" fill="#FBBC05"></path><path d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z" fill="#34A853"></path><path fill="none" d="M0 0h18v18H0z"></path></g></svg>
              &nbsp;&nbsp; Logout
          </button>
      )}
    </>
  );
}
