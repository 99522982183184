import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";
import React, { useEffect, useState } from "react";
import Session from "./session";
import axios from "axios";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";

const xmlUrl = "https://data.metroworldnews.com/api/reportes/getReporteXml?site=";

export default function StatusBar(props) {
  const [mensaje, setMensaje] = useState("");
  const { session } = props;
  useEffect(() => {
    let url = "https://data.metroworldnews.com/";
    axios
      .get(url)
      .then((res) => {
        setMensaje(true);
      })
      .catch((err) => {
        setMensaje(false);
        console.log(err.response);
      });
  }, []);

  const realoadPage = () => {
    window.location.reload(false);
  };

  return (
    <>
      <header>
        <div className="navbar navbar-dark bg-success shadow-sm ">
          <div className="container">
            <div className="navbar-brand d-flex align-items-center justify-content-between">
              <FaGlobe />
              <h1>TOOLS MWN</h1>
            </div>
            <div className="groupButtom">
              <button className={mensaje ? "btn btnOnline" : "btn btn-danger"} onClick={realoadPage}>
                Estado de API :{" "}
                {mensaje ? (
                  <>
                    Online <i className="fas fa-wifi"></i>
                  </>
                ) : (
                  "Offline"
                )}
              </button>
              <Session session={session} />
            </div>
          </div>
        </div>
      </header>
      {props.session.status ? (
        <Navbar variant="light">
          <Container>
            <Nav className="me-auto">
              {session.perfil === 1 ? <Nav.Link href="/monitoreo">Monitoreo</Nav.Link> : null}
              {session.accesos.tags ? <Nav.Link href="/tags">Tags</Nav.Link> : null}
              {session.accesos.redirect ? <Nav.Link href="/redirect">Redirect</Nav.Link> : null}
              {session.accesos.report || session.accesos.report_compartidos ? (
                <NavDropdown title="Reportes">
                  {session.accesos.report ? <NavDropdown.Item href="/report">Report</NavDropdown.Item> : null}
                  {session.accesos.report_compartidos ? <NavDropdown.Item href="/report_2">Post Compartidos</NavDropdown.Item> : null}
                  {session.perfil === 1 ? <NavDropdown.Item href="/reporteSession">Reporte de Sesion</NavDropdown.Item> : null}
                </NavDropdown>
              ) : null}
              {session.accesos.report_xml ? (
                <NavDropdown target="_blank" title="Reporte Xml" id="basic-nav-dropdown">
                  <NavDropdown.Item target="_blank" href={xmlUrl + ""}>
                    Todos
                  </NavDropdown.Item>
                  <NavDropdown.Item target="_blank" href={xmlUrl + "mwnpuertorico"}>
                    MWN Puerto Rico
                  </NavDropdown.Item>
                  <NavDropdown.Item target="_blank" href={xmlUrl + "activo"}>
                    Activo
                  </NavDropdown.Item>
                  <NavDropdown.Item target="_blank" href={xmlUrl + "mwn"}>
                    MWN
                  </NavDropdown.Item>
                  <NavDropdown.Item target="_blank" href={xmlUrl + "mwnmexico"}>
                    MWN México
                  </NavDropdown.Item>
                  <NavDropdown.Item target="_blank" href={xmlUrl + "nuevamujer"}>
                    Nueva Mujer
                  </NavDropdown.Item>
                  <NavDropdown.Item target="_blank" href={xmlUrl + "mwnchile"}>
                    MWN Chile
                  </NavDropdown.Item>
                  <NavDropdown.Item target="_blank" href={xmlUrl + "elcalce"}>
                    El calce
                  </NavDropdown.Item>
                  <NavDropdown.Item target="_blank" href={xmlUrl + "mwncolombia"}>
                    MWN Colombia
                  </NavDropdown.Item>
                  <NavDropdown.Item target="_blank" href={xmlUrl + "mwnecuador"}>
                    MWN Ecuador
                  </NavDropdown.Item>
                  <NavDropdown.Item target="_blank" href={xmlUrl + "sabrosia"}>
                    Sabrosia
                  </NavDropdown.Item>
                  <NavDropdown.Item target="_blank" href={xmlUrl + "fayerwayer"}>
                    Fayerwayer
                  </NavDropdown.Item>
                  <NavDropdown.Item target="_blank" href={xmlUrl + "ferplei"}>
                    Ferplei
                  </NavDropdown.Item>
                  <NavDropdown.Item target="_blank" href={xmlUrl + "mwnargentina"}>
                    MWN Argentina
                  </NavDropdown.Item>
                  <NavDropdown.Item target="_blank" href={xmlUrl + "mwnperu"}>
                    MWN Perú
                  </NavDropdown.Item>
                  <NavDropdown.Item target="_blank" href={xmlUrl + "comutricolor"}>
                    Comutricolor
                  </NavDropdown.Item>
                  <NavDropdown.Item target="_blank" href={xmlUrl + "novamulher"}>
                    Nova Mulher
                  </NavDropdown.Item>
                </NavDropdown>
              ) : null}
              {session.accesos.instagram ? (
                <Nav.Link href="https://dash.metroworldnews.com/instagram/?t=1" target="_blank">
                  Instagram
                </Nav.Link>
              ) : null}
              {session.accesos.reporte_osTicket ? <Nav.Link href="/reportes">Osticket</Nav.Link> : null}
              {session.accesos.push ? (
                <NavDropdown title="Push Noti." id="basic-nav-dropdown">
                  <NavDropdown.Item href="/push">Push Instantanea</NavDropdown.Item>
                  <NavDropdown.Item href="/pushProgramada">Push Programada</NavDropdown.Item>
                  <NavDropdown.Item href="/pushReporte">Push Reporte</NavDropdown.Item>
                </NavDropdown>
              ) : null}
              {session.accesos.twitter || session.accesos.facebook ? (
                <NavDropdown title="Social Pilot">
                 {session.accesos.twitter ? <NavDropdown.Item target="_blank" href="https://dash.metroworldnews.com/arcTwitter/dash/crud/articles/list_articles.php">Twitter</NavDropdown.Item> : null}
                 {session.accesos.facebook ? <NavDropdown.Item target="_blank"  href="https://dash.metroworldnews.com/arcFacebook/dash/crud/articles/list_articles.php">Facebook</NavDropdown.Item> : null} 
		</NavDropdown>
              ) : null}
              {session.perfil === 1 ? (
                <NavDropdown title="Mantenedores">
                  {/* <NavDropdown.Item href="/sitios">Sitios</NavDropdown.Item> */}
                  <NavDropdown.Item href="/usuarios">Usuarios</NavDropdown.Item>
                </NavDropdown>
              ) : null}
            </Nav>
          </Container>
        </Navbar>
      ) : null}
    </>
  );
}
