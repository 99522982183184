import Push from "./push";
import Reportes from "./report";
import Crdirect from "./redirect";
import Usuarios from "./usuarios";
import StatusBar from "./statusBar";
import CargaTags from "./cargaTags";
import Monitoreo from "./monitoreo";
import Reportes2 from "./reportClonado";
import CheckLogin from "../hooks/checkLogin";
import ReporteSession from "./reporteSesion";
import PushProgramada from "./pushProgramada";
import PushReporte from "./pushReporte";
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Twitter from "./twitter";
import Facebook from "./facebook";
// import Sitios from "./sitios";

export default function Content() {
  const [session, setSession] = useState({ status: false });

  useEffect(() => {
    setSession(CheckLogin);
  }, []);

  return (
    <>
      <StatusBar session={session} />
      {session.status ? (
        <Router>
          <Switch>
            <Route path="/redirect">
              {session.accesos.redirect ? (
                <>
                  <Crdirect token={session.token} />
                </>
              ) : (
                <Redirect to="/" />
              )}
            </Route>
            <Route path="/report">
              {session.accesos.report ? (
                <>
                  <Reportes token={session.token} permiso={session.accesos.report} />
                </>
              ) : (
                <Redirect to="/" />
              )}
            </Route>
            <Route path="/report_2">
              {session.accesos.report_compartidos ? (
                <>
                  <Reportes2 token={session.token} />
                </>
              ) : (
                <Redirect to="/" />
              )}
            </Route>
            <Route path="/usuarios">
              {session.perfil === 1 ? (
                <>
                  <Usuarios token={session.token} />
                </>
              ) : (
                <Redirect to="/" />
              )}
            </Route>
            <Route path="/reporteSession">
              {session.perfil === 1 ? (
                <>
                  <ReporteSession token={session.token} />
                </>
              ) : (
                <Redirect to="/" />
              )}
            </Route>
            <Route path="/push">
              {session.accesos.push ? (
                <>
                  <Push token={session.token} />
                </>
              ) : (
                <Redirect to="/" />
              )}
            </Route>
            <Route path="/pushProgramada">
              {session.accesos.push ? (
                <>
                  <PushProgramada token={session.token} />
                </>
              ) : (
                <Redirect to="/" />
              )}
            </Route>
            <Route path="/pushReporte">
              {session.accesos.push ? (
                <>
                  <PushReporte token={session.token} />
                </>
              ) : (
                <Redirect to="/" />
              )}
            </Route>
            <Route path="/tags">
              {session.accesos.tags ? (
                <>
                  <CargaTags token={session.token} />
                </>
              ) : (
                <Redirect to="/" />
              )}
            </Route>
            <Route path="/monitoreo">
              {session.perfil === 1 ? (
                <>
                  <Monitoreo token={session.token} />
                </>
              ) : (
                <Redirect to="/" />
              )}
            </Route>
            <Route path="/twitter" token={session.token}>
              <Twitter />
            </Route>
            <Route path="/facebookPost" token={session.token}>
              <Facebook />
            </Route>
            {/* <Route path="/sitios" token={session.token}>
              <Sitios />
            </Route> */}
            <Route path="/"></Route>
          </Switch>
        </Router>
      ) : null}
    </>
  );
}
