import { makeInstance } from "../utils/conectionApiConfig";
import Swal from "sweetalert2";
import React, { useState, useEffect } from "react";
import withReactContent from "sweetalert2-react-content";
import { Button, Modal, ModalBody, ModalHeader, FormGroup, ModalFooter } from "reactstrap";
import Select from "react-select";

import { useTable, usePagination } from "react-table";

import "react-datepicker/dist/react-datepicker.css";

export default function Usuarios({ token }) {
  //Swal//
  const MySwal = withReactContent(Swal);
  //Modal//
  const [modalInsert, setModalInsert] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalValidacion, setModalValidacion] = useState(false);
  const [modalAlerta, setModalAlerta] = useState(false);
  const [modalCheck, setModalCheck] = useState(false);
  const [mensajeModalAlerta, setMensajeModalAlerta] = useState("");
  //Usuario//
  const [email, setEmail] = useState("");
  const [nombreUsuario, setNombreUsuario] = useState("");
  const [perfil, setPerfil] = useState(0);
  //Sitios y Permisos
  const [sitios, setSitios] = useState([]);
  const [permisos, setPermisos] = useState([]);
  //Check Sitios y Permisos
  const [checkSite, setCheckSite] = useState([]);
  const [checkPermisos, setCheckPermisos] = useState([]);
  //User-Site//
  const [mwn, setMwn] = useState(false);
  const [mwnchile, setMwnchile] = useState(false);
  const [mwnmexico, setMwnmexico] = useState(false);
  const [mwncolombia, setMwncolombia] = useState(false);
  const [mwnecuador, setMwnecuador] = useState(false);
  const [mwnperu, setMwnperu] = useState(false);
  const [ferplei, setFerplei] = useState(false);
  const [fayerwayer, setFayerwayer] = useState(false);
  const [nuevamujer, setNuevamujer] = useState(false);
  const [mwnbrasil, setMwnbrasil] = useState(false);
  const [novamulher, setNovamulher] = useState(false);
  const [comutricolor, setComutricolor] = useState(false);
  const [mwnpuertorico, setMwnpuertorico] = useState(false);
  const [elcalce, setElcalce] = useState(false);
  const [sabrosia, setSabrosia] = useState(false);
  const [activo, setActivo] = useState(false);
  const [mwnargentina, setMwnargentina] = useState(false);
  const [emisorasunidas, setEmisorasunidas] = useState(false);
  const [latronadora, setLatronadora] = useState(false);
  const [mwnguatemala, setMwnguatemala] = useState(false);
  const [sagrosso, setSagrosso] = useState(false);
  const [showyfama, setShowyFama] = useState(false);
  //Permisos//
  const [tags, setTags] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [reporte, setReporte] = useState(false);
  const [reporteCompartidos, setReporteCompartidos] = useState(false);
  const [reporteXML, setReporteXML] = useState(false);
  const [instagram, setInstagram] = useState(false);
  const [reporteOsTicket, setReporteOsTicket] = useState(false);
  const [push, setPush] = useState(false);
  const [twitter, setTwitter] = useState(false);
  const [facebook, setFacebook] = useState(false);
  const perfiles = [
    {
      value: "0",
      label: "Usuario",
    },
    {
      value: "1",
      label: "Administrador",
    },
  ];
  //Constantes//
  const [isLoading, setIsLoading] = useState(true);

  //Resultado
  const [body, setBody] = useState([]);

 const instance = makeInstance(token,'user');

  const header = [
    { Header: "Nombre", accessor: "nombre" },
    { Header: "Email", accessor: "email" },
    {
      Header: "Acciones",
      accessor: "acciones",
      Cell: (e) => (
        <div className="buttonGroup">
          <button
            className="btn see"
            onClick={() => {
              checkUser(e.value);
            }}
          >
            <i className="far fa-eye"></i>
            Ver permisos
          </button>
          <button
            className="btn edit"
            onClick={() => {
              editUser(e.value);
            }}
          >
            <i className="fas fa-pencil-alt"></i>
            Editar
          </button>
          <button
            className="btn danger"
            onClick={() => {
              setModalValidacion(true);
              setEmail(e.value);
            }}
          >
            <i className="far fa-trash-alt"></i>
            Eliminar
          </button>
        </div>
      ),
    },
  ];

  const crearUsuario = (e) => {
    e.preventDefault();
    var data = {
      usuario: {
        email: email,
        nombre: nombreUsuario,
        perfil: perfil,
        status: 1,
      },
      accesos: [
        { email: email, modulo: "tags", status: tags ? 1 : 0 },
        { email: email, modulo: "redirect", status: redirect ? 1 : 0 },
        { email: email, modulo: "report", status: reporte ? 1 : 0 },
        {
          email: email,
          modulo: "report_compartidos",
          status: reporteCompartidos ? 1 : 0,
        },
        { email: email, modulo: "report_xml", status: reporteXML ? 1 : 0 },
        { email: email, modulo: "instagram", status: instagram ? 1 : 0 },
        {
          email: email,
          modulo: "reporte_osTicket",
          status: reporteOsTicket ? 1 : 0,
        },
        {
          email: email,
          modulo: "push",
          status: push ? 1 : 0,
        },
        {
          email: email,
          modulo: "twitter",
          status: twitter ? 1 : 0,
        },
        {
          email: email,
          modulo: "facebook",
          status: facebook ? 1 : 0,
        },
      ],
      sitios: [],
    };
    if (mwn) {
      data.sitios.push({ email: email, sitio: "mwn" });
    }
    if (mwnchile) {
      data.sitios.push({ email: email, sitio: "mwnchile" });
    }
    if (mwnmexico) {
      data.sitios.push({ email: email, sitio: "mwnmexico" });
    }
    if (mwncolombia) {
      data.sitios.push({ email: email, sitio: "mwncolombia" });
    }
    if (mwnecuador) {
      data.sitios.push({ email: email, sitio: "mwnecuador" });
    }
    if (mwnperu) {
      data.sitios.push({ email: email, sitio: "mwnperu" });
    }
    if (ferplei) {
      data.sitios.push({ email: email, sitio: "ferplei" });
    }
    if (fayerwayer) {
      data.sitios.push({ email: email, sitio: "fayerwayer" });
    }
    if (nuevamujer) {
      data.sitios.push({ email: email, sitio: "nuevamujer" });
    }
    if (mwnbrasil) {
      data.sitios.push({ email: email, sitio: "mwnbrasil" });
    }
    if (novamulher) {
      data.sitios.push({ email: email, sitio: "novamulher" });
    }
    if (comutricolor) {
      data.sitios.push({ email: email, sitio: "comutricolor" });
    }
    if (mwnpuertorico) {
      data.sitios.push({ email: email, sitio: "mwnpuertorico" });
    }
    if (elcalce) {
      data.sitios.push({ email: email, sitio: "elcalce" });
    }
    if (sabrosia) {
      data.sitios.push({ email: email, sitio: "sabrosia" });
    }
    if (activo) {
      data.sitios.push({ email: email, sitio: "activo" });
    }
    if (mwnargentina) {
      data.sitios.push({ email: email, sitio: "mwnargentina" });
    }
    if (emisorasunidas) {
      data.sitios.push({ email: email, sitio: "emisorasunidas" });
    }
    if (latronadora) {
      data.sitios.push({ email: email, sitio: "latronadora" });
    }
    if (mwnguatemala) {
      data.sitios.push({ email: email, sitio: "mwnguatemala" });
    }
    if (sagrosso) {
      data.sitios.push({ email: email, sitio: "sagrosso" });
    }
    if (showyfama) {
      data.sitios.push({ email: email, sitio: "show-y-fama" });
    }
    instance
      .post("/creaUsuarios", data)
      .then(() => {
        setModalInsert(false);
        getUsers();
        setMensajeModalAlerta("Registro Ingresado!");
        setModalAlerta(true);
      })
      .catch((err) => {
        console.log(err.response);
        return MySwal.fire(<p>{err.response.data.message}</p>);
      });
  };

  const editaUsuario = (e) => {
    e.preventDefault();
    var data = {
      llave: email,
      usuario: {
        nombre: nombreUsuario,
        perfil: perfil,
        status: 1,
      },
      accesos: [
        { modulo: "tags", status: tags ? 1 : 0 },
        { modulo: "redirect", status: redirect ? 1 : 0 },
        { modulo: "report", status: reporte ? 1 : 0 },
        {
          modulo: "report_compartidos",
          status: reporteCompartidos ? 1 : 0,
        },
        { modulo: "report_xml", status: reporteXML ? 1 : 0 },
        { modulo: "instagram", status: instagram ? 1 : 0 },
        {
          modulo: "reporte_osTicket",
          status: reporteOsTicket ? 1 : 0,
        },
        {
          modulo: "push",
          status: push ? 1 : 0,
        },
        {
          modulo: "twitter",
          status: twitter ? 1 : 0,
        },
        {
          modulo: "facebook",
          status: facebook ? 1 : 0,
        },
      ],
      sitios: [],
    };
    if (mwn) {
      data.sitios.push({ email: email, sitio: "mwn" });
    }
    if (mwnchile) {
      data.sitios.push({ email: email, sitio: "mwnchile" });
    }
    if (mwnmexico) {
      data.sitios.push({ email: email, sitio: "mwnmexico" });
    }
    if (mwncolombia) {
      data.sitios.push({ email: email, sitio: "mwncolombia" });
    }
    if (mwnecuador) {
      data.sitios.push({ email: email, sitio: "mwnecuador" });
    }
    if (mwnperu) {
      data.sitios.push({ email: email, sitio: "mwnperu" });
    }
    if (ferplei) {
      data.sitios.push({ email: email, sitio: "ferplei" });
    }
    if (fayerwayer) {
      data.sitios.push({ email: email, sitio: "fayerwayer" });
    }
    if (nuevamujer) {
      data.sitios.push({ email: email, sitio: "nuevamujer" });
    }
    if (mwnbrasil) {
      data.sitios.push({ email: email, sitio: "mwnbrasil" });
    }
    if (novamulher) {
      data.sitios.push({ email: email, sitio: "novamulher" });
    }
    if (comutricolor) {
      data.sitios.push({ email: email, sitio: "comutricolor" });
    }
    if (mwnpuertorico) {
      data.sitios.push({ email: email, sitio: "mwnpuertorico" });
    }
    if (elcalce) {
      data.sitios.push({ email: email, sitio: "elcalce" });
    }
    if (sabrosia) {
      data.sitios.push({ email: email, sitio: "sabrosia" });
    }
    if (activo) {
      data.sitios.push({ email: email, sitio: "activo" });
    }
    if (mwnargentina) {
      data.sitios.push({ email: email, sitio: "mwnargentina" });
    }
    if (emisorasunidas) {
      data.sitios.push({ email: email, sitio: "emisorasunidas" });
    }
    if (latronadora) {
      data.sitios.push({ email: email, sitio: "latronadora" });
    }
    if (mwnguatemala) {
      data.sitios.push({ email: email, sitio: "mwnguatemala" });
    }
    if (sagrosso) {
      data.sitios.push({ email: email, sitio: "sagrosso" });
    }
    if (showyfama) {
      data.sitios.push({ email: email, sitio: "show-y-fama" });
    }
    instance
      .post("/editaUsuario", data)
      .then(({ data }) => {
        getUsers();
        setModalEdit(false);
        setMensajeModalAlerta("Registro Editado!");
        setModalAlerta(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkUser = (email) => {
    instance
      .get("/obtieneUsuario/" + email)
      .then(({ data }) => {
        var elemento = data.result;
        setEmail(elemento.email);
        setNombreUsuario(elemento.nombre);
        setPerfil(elemento.perfil);
        setTags(elemento.tags);
        setRedirect(elemento.redirect);
        setReporte(elemento.report);
        setReporteCompartidos(elemento.report_compartidos);
        setReporteXML(elemento.report_xml);
        setInstagram(elemento.instagram);
        setReporteOsTicket(elemento.reporte_osTicket);
        setPush(elemento.push);
        setTwitter(elemento.twitter);
        setFacebook(elemento.facebook);
        setMwn(elemento.mwn !== undefined ? true : false);
        setMwnchile(elemento.mwnchile !== undefined ? true : false);
        setMwnmexico(elemento.mwnmexico !== undefined ? true : false);
        setMwncolombia(elemento.mwncolombia !== undefined ? true : false);
        setMwnecuador(elemento.mwnecuador !== undefined ? true : false);
        setMwnperu(elemento.mwnperu !== undefined ? true : false);
        setFerplei(elemento.ferplei !== undefined ? true : false);
        setFayerwayer(elemento.fayerwayer !== undefined ? true : false);
        setNuevamujer(elemento.nuevamujer !== undefined ? true : false);
        setMwnbrasil(elemento.mwnbrasil !== undefined ? true : false);
        setNovamulher(elemento.novamulher !== undefined ? true : false);
        setComutricolor(elemento.comutricolor !== undefined ? true : false);
        setMwnpuertorico(elemento.mwnpuertorico !== undefined ? true : false);
        setElcalce(elemento.elcalce !== undefined ? true : false);
        setSabrosia(elemento.sabrosia !== undefined ? true : false);
        setActivo(elemento.activo !== undefined ? true : false);
        setMwnargentina(elemento.mwnargentina !== undefined ? true : false);
        setEmisorasunidas(elemento.emisorasunidas !== undefined ? true : false);
        setLatronadora(elemento.latronadora !== undefined ? true : false);
        setMwnguatemala(elemento.mwnguatemala !== undefined ? true : false);
        setSagrosso(elemento.sagrosso !== undefined ? true : false);
        setShowyFama(elemento.showyfama !== undefined ? true : false);
      })
      .catch((err) => {
        console.log(err);
      })
      .then(() => {
        setModalCheck(true);
      });
  };

  const editUser = (email) => {
    instance
      .get("/obtieneUsuario/" + email)
      .then(({ data }) => {
        var elemento = data.result;
        setEmail(elemento.email);
        setNombreUsuario(elemento.nombre);
        setPerfil(elemento.perfil);
        setTags(elemento.tags);
        setRedirect(elemento.redirect);
        setReporte(elemento.report);
        setReporteCompartidos(elemento.report_compartidos);
        setReporteXML(elemento.report_xml);
        setInstagram(elemento.instagram);
        setReporteOsTicket(elemento.reporte_osTicket);
        setPush(elemento.push);
        setTwitter(elemento.twitter);
        setFacebook(elemento.facebook);
        setMwn(elemento.mwn !== undefined ? true : false);
        setMwnchile(elemento.mwnchile !== undefined ? true : false);
        setMwnmexico(elemento.mwnmexico !== undefined ? true : false);
        setMwncolombia(elemento.mwncolombia !== undefined ? true : false);
        setMwnecuador(elemento.mwnecuador !== undefined ? true : false);
        setMwnperu(elemento.mwnperu !== undefined ? true : false);
        setFerplei(elemento.ferplei !== undefined ? true : false);
        setFayerwayer(elemento.fayerwayer !== undefined ? true : false);
        setNuevamujer(elemento.nuevamujer !== undefined ? true : false);
        setMwnbrasil(elemento.mwnbrasil !== undefined ? true : false);
        setNovamulher(elemento.novamulher !== undefined ? true : false);
        setComutricolor(elemento.comutricolor !== undefined ? true : false);
        setMwnpuertorico(elemento.mwnpuertorico !== undefined ? true : false);
        setElcalce(elemento.elcalce !== undefined ? true : false);
        setSabrosia(elemento.sabrosia !== undefined ? true : false);
        setActivo(elemento.activo !== undefined ? true : false);
        setMwnargentina(elemento.mwnargentina !== undefined ? true : false);
        setEmisorasunidas(elemento.emisorasunidas !== undefined ? true : false);
        setLatronadora(elemento.latronadora !== undefined ? true : false);
        setMwnguatemala(elemento.mwnguatemala !== undefined ? true : false);
        setSagrosso(elemento.sagrosso !== undefined ? true : false);
        setShowyFama(elemento.showyfama !== undefined ? true : false);

      })
      .catch((err) => {
        console.log(err);
      })
      .then(() => {
        setModalEdit(true);
      });
  };

  const deleteUser = (email) => {
    instance
      .delete("/eliminaUsuario/" + email)
      .then(() => {
        getUsers();
        setModalValidacion(false);
        setMensajeModalAlerta("Registro Eliminado!");
        setModalAlerta(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getUsers = () => {
    instance
      .get("/obtieneUsuarios")
      .then(({ data }) => {
        setBody(data);
      })
      .catch((err) => {
        console.log(err);
      })
      .then(() => {
        console.log("Informacion de usuarios obtenidas!");
        setIsLoading(false);
      });
  };

  const filtrarContenido = (input) => {
    // console.log("Function filtrarNombre");
    setIsLoading(true);
    var url = input != "" ? `obtieneUsuarios/${input}` : "obtieneUsuarios/";
    instance
      .get(url)
      .then(({ data }) => {
        setBody(data);
      })
      .catch((err) => {
        console.log(err);
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  const getSitios = () => {
    instance
      .get("getSites")
      .then(({ data }) => {
        //console.log("---> ",data);
      })
      .catch((err) => console.log("Error en la obtencion de Sitios: ", err))
      .then(() => console.log("Sitios obtenidos"));
  };

  const getPermisos = () => {
    instance
      .get("getPermissions")
      .then(({ data }) => {
        //console.log(data);
      })
      .catch((err) => console.log("Error en la obtencion de Sitios: ", err))
      .then(() => console.log("Sitios obtenidos"));
  };

  useEffect(() => {
    getUsers();
    getSitios();
    getPermisos();
  }, []);

  const GeneraTabla = () => {
    const data = React.useMemo(() => body, []);
    const columns = React.useMemo(() => header, []);
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      //   rows,
      prepareRow,
      page, // Instead of using 'rows', we'll use page,
      // which has only the rows for the active page

      // The rest of these things are super handy, too ;)
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns,
        data,
        initialState: { pageIndex: 0 },
      },
      usePagination
    );

    return (
      <>
        <table {...getTableProps()} className="table table-striped table-hover">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="pagination">
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {"<<"}
          </button>{" "}
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {"<"}
          </button>{" "}
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            {">"}
          </button>{" "}
          <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            {">>"}
          </button>{" "}
          <span className="numeroActualPage">
            Página{" "}
            <strong>
              {pageIndex + 1} de {pageOptions.length}
            </strong>{" "}
          </span>
          <span className="numeroGoPage">
            Ir a la página:{" "}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "100px" }}
            />
          </span>{" "}
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            placeholder={"Seleccione"}
          >
            {[10, 20, 50, 200, 500, 3000].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Mostrar {pageSize}
              </option>
            ))}
          </select>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="container mb-5">
        <div className="row">
          <div className="col-12">
            <div className="box">
              <div className="box__header spaceBetween">
                <h3>Usuarios</h3>
                <input
                  className="col-3"
                  placeholder="Nombre"
                  size="sm"
                  onChange={(e) => {
                    filtrarContenido(e.target.value);
                  }}
                />
                <button
                  onClick={() => {
                    setEmail("");
                    setNombreUsuario("");
                    setTags(false);
                    setRedirect(false);
                    setReporte(false);
                    setReporteCompartidos(false);
                    setReporteXML(false);
                    setReporteOsTicket(false);
                    setTwitter(false);
                    setFacebook(false);
                    setPush(false);
                    setInstagram(false);
                    setMwn(false);
                    setMwnchile(false);
                    setMwnmexico(false);
                    setMwncolombia(false);
                    setMwnecuador(false);
                    setMwnperu(false);
                    setFerplei(false);
                    setFayerwayer(false);
                    setNuevamujer(false);
                    setMwnbrasil(false);
                    setNovamulher(false);
                    setComutricolor(false);
                    setMwnpuertorico(false);
                    setElcalce(false);
                    setSabrosia(false);
                    setActivo(false);
                    setMwnargentina(false);
                    setEmisorasunidas(false);
                    setLatronadora(false);
                    setMwnguatemala(false);
                    setSagrosso(false);
                    setShowyFama(false);
                    setModalInsert(!modalInsert);
                  }}
                  className="btn newUser"
                >
                  <i className="fas fa-user-plus"></i>
                  Nuevo Usuario
                </button>
              </div>
              <div className="box__body">
                <div className="alert alert-success" role="alert">
                  {isLoading ? (
                    <>
                      <div className="modales"></div>
                      <div className="loader"></div>
                    </>
                  ) : (
                    <GeneraTabla />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={modalInsert} size="xl">
        <ModalHeader>Ingresa Nuevo Usuario</ModalHeader>
        <form onSubmit={crearUsuario}>
          <ModalBody>
            <FormGroup>
              <input
                type="email"
                className="form-control"
                required="true"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                placeholder="Ingrese Email"
              ></input>
            </FormGroup>
            <FormGroup>
              <input
                type="text"
                className="form-control"
                required="true"
                onChange={(e) => {
                  setNombreUsuario(e.target.value);
                }}
                placeholder="Ingrese nombre de usuario"
              ></input>
            </FormGroup>
            <FormGroup>
              <label>Seleccione perfil de usuario</label>
              <Select
                options={perfiles}
                required="true"
                defaultValue={{ label: "Usuario", value: 0 }}
                onChange={(e) => {
                  setPerfil(e.value);
                }}
              />
            </FormGroup>
            <hr></hr>
            <label>Permisos</label>
            <FormGroup className="opciones">
              <input
                type="checkbox"
                className="btn-check"
                id="btncheck1"
                autoComplete="off"
                checked={tags}
                onChange={(e) => {
                  setTags(!tags);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btncheck1">
                <i className="far fa-clock"></i> Tags
              </label>

              <input
                type="checkbox"
                className="btn-check"
                id="btncheck2"
                autoComplete="off"
                checked={redirect}
                onChange={() => {
                  setRedirect(!redirect);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btncheck2">
                <i className="far fa-clock"></i> Redirect
              </label>

              <input
                type="checkbox"
                className="btn-check"
                id="btncheck3"
                autoComplete="off"
                checked={reporte}
                onChange={() => {
                  setReporte(!reporte);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btncheck3">
                <i className="far fa-clock"></i> Reporte
              </label>

              <input
                type="checkbox"
                className="btn-check"
                id="btncheck4"
                autoComplete="off"
                checked={reporteCompartidos}
                onChange={() => {
                  setReporteCompartidos(!reporteCompartidos);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btncheck4">
                <i className="far fa-clock"></i> Reporte Compartidos
              </label>

              <input
                type="checkbox"
                className="btn-check"
                id="btncheck5"
                autoComplete="off"
                checked={reporteXML}
                onChange={() => {
                  setReporteXML(!reporteXML);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btncheck5">
                <i className="far fa-clock"></i> Reporte XML
              </label>

              <input
                type="checkbox"
                className="btn-check"
                id="btncheck6"
                autoComplete="off"
                checked={instagram}
                onChange={() => {
                  setInstagram(!instagram);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btncheck6">
                <i className="far fa-clock"></i> Instagram
              </label>

              <input
                type="checkbox"
                className="btn-check"
                id="btncheck7"
                autoComplete="off"
                checked={reporteOsTicket}
                onChange={() => {
                  setReporteOsTicket(!reporteOsTicket);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btncheck7">
                <i className="far fa-clock"></i> Reporte OS Ticket
              </label>

              <input
                type="checkbox"
                className="btn-check"
                id="btncheck8"
                autoComplete="off"
                checked={push}
                onChange={() => {
                  setPush(!push);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btncheck8">
                <i className="far fa-clock"></i> Push
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="btncheck9"
                autoComplete="off"
                checked={twitter}
                onChange={() => {
                  setTwitter(!twitter);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btncheck9">
                <i className="far fa-clock"></i> Twitter
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="btncheck10"
                autoComplete="off"
                checked={facebook}
                onChange={() => {
                  setFacebook(!facebook);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btncheck10">
                <i className="far fa-clock"></i> Facebook
              </label>
            </FormGroup>
            <hr></hr>
            <label>Sitios 1</label>
            <FormGroup className="opciones">
              <input
                type="checkbox"
                className="btn-check"
                id="btnSite1"
                autoComplete="off"
                checked={mwn}
                onChange={() => {
                  setMwn(!mwn);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btnSite1">
                <i className="far fa-clock"></i> Metro World News
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="btnSite2"
                autoComplete="off"
                checked={mwnchile}
                onChange={() => {
                  setMwnchile(!mwnchile);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btnSite2">
                <i className="far fa-clock"></i> Publimetro Chile
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="btnSite3"
                autoComplete="off"
                checked={mwnmexico}
                onChange={() => {
                  setMwnmexico(!mwnmexico);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btnSite3">
                <i className="far fa-clock"></i> Publimetro Mexico
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="btnSite4"
                autoComplete="off"
                checked={mwncolombia}
                onChange={() => {
                  setMwncolombia(!mwncolombia);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btnSite4">
                <i className="far fa-clock"></i> Publimetro Colombia
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="btnSite5"
                autoComplete="off"
                checked={mwnecuador}
                onChange={() => {
                  setMwnecuador(!mwnecuador);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btnSite5">
                <i className="far fa-clock"></i> Metro Ecuador
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="btnSite6"
                autoComplete="off"
                checked={mwnperu}
                onChange={() => {
                  setMwnperu(!mwnperu);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btnSite6">
                <i className="far fa-clock"></i> Publimetro Peru
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="btnSite7"
                autoComplete="off"
                checked={ferplei}
                onChange={() => {
                  setFerplei(!ferplei);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btnSite7">
                <i className="far fa-clock"></i> Ferplei
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="btnSite8"
                autoComplete="off"
                checked={fayerwayer}
                onChange={() => {
                  setFayerwayer(!fayerwayer);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btnSite8">
                <i className="far fa-clock"></i> FayerWayer
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="btnSite9"
                autoComplete="off"
                checked={nuevamujer}
                onChange={() => {
                  setNuevamujer(!nuevamujer);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btnSite9">
                <i className="far fa-clock"></i> Nueva Mujer
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="btnSite10"
                autoComplete="off"
                checked={mwnbrasil}
                onChange={() => {
                  setMwnbrasil(!mwnbrasil);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btnSite10">
                <i className="far fa-clock"></i> Metro World News Brasil
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="btnSite11"
                autoComplete="off"
                checked={novamulher}
                onChange={() => {
                  setNovamulher(!novamulher);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btnSite11">
                <i className="far fa-clock"></i> Nova Mulher
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="btnSite12"
                autoComplete="off"
                checked={comutricolor}
                onChange={() => {
                  setComutricolor(!comutricolor);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btnSite12">
                <i className="far fa-clock"></i> ComuTricolor
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="btnSite13"
                autoComplete="off"
                checked={mwnpuertorico}
                onChange={() => {
                  setMwnpuertorico(!mwnpuertorico);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btnSite13">
                <i className="far fa-clock"></i> Metro Puerto Rico
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="btnSite14"
                autoComplete="off"
                checked={elcalce}
                onChange={() => {
                  setElcalce(!elcalce);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btnSite14">
                <i className="far fa-clock"></i> El Calce
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="btnSite15"
                autoComplete="off"
                checked={sabrosia}
                onChange={() => {
                  setSabrosia(!sabrosia);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btnSite15">
                <i className="far fa-clock"></i> Sabrosia
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="btnSite16"
                autoComplete="off"
                checked={activo}
                onChange={() => {
                  setActivo(!activo);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btnSite16">
                <i className="far fa-clock"></i> Activo
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="btnSite17"
                autoComplete="off"
                checked={mwnargentina}
                onChange={() => {
                  setMwnargentina(!mwnargentina);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btnSite17">
                <i className="far fa-clock"></i> Metro World Newsargentina
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="btnSite18"
                autoComplete="off"
                checked={emisorasunidas}
                onChange={() => {
                  setEmisorasunidas(!emisorasunidas);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btnSite18">
                <i className="far fa-clock"></i> Emisoras Unidas
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="btnSite19"
                autoComplete="off"
                checked={latronadora}
                onChange={() => {
                  setLatronadora(!latronadora);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btnSite19">
                <i className="far fa-clock"></i> La Tronadora
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="btnSite20"
                autoComplete="off"
                checked={mwnguatemala}
                onChange={() => {
                  setMwnguatemala(!mwnguatemala);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btnSite20">
                <i className="far fa-clock"></i> Publinews Guatemala
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="btnSite21"
                autoComplete="off"
                checked={sagrosso}
                onChange={() => {
                  setSagrosso(!sagrosso);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btnSite21">
                <i className="far fa-clock"></i> Sagrosso
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="btnSite29"
                autoComplete="off"
                checked={showyfama}
                onChange={() => {
                  setShowyFama(!showyfama);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btnSite29">
                <i className="far fa-clock"></i> Show y Fama
              </label>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="success" type="submit">
              Guardar
            </Button>
            <Button
              color="danger"
              onClick={() => {
                setModalInsert(!modalInsert);
              }}
            >
              Cerrar
            </Button>
          </ModalFooter>
        </form>
      </Modal>
      <Modal isOpen={modalCheck}>
        <ModalHeader>Detalles de Usuario</ModalHeader>
        <ModalBody>
          <FormGroup>
            <label>Email</label>
            <input type="email" className="form-control" readOnly={true} value={email}></input>
          </FormGroup>
          <FormGroup>
            <label>Nombre usuario</label>
            <input type="text" className="form-control" required="true" value={nombreUsuario} readOnly={true}></input>
          </FormGroup>
          <FormGroup>
            <label>Perfil</label>
            <Select options={perfiles} required="true" isDisabled="true" defaultValue={perfil === 0 ? { label: "Usuario", value: 0 } : { label: "Administrador", value: 1 }} />
          </FormGroup>
          <hr></hr>
          <label>Permisos</label>
          <FormGroup className="opciones">
            <input type="checkbox" className="btn-check" id="btncheck1" autoComplete="off" checked={tags} />
            <label className="btn btn-outline-success" htmlFor="btncheck1">
              <i className="far fa-clock"></i> Tags
            </label>

            <input type="checkbox" className="btn-check" id="btncheck2" autoComplete="off" checked={redirect} />
            <label className="btn btn-outline-success" htmlFor="btncheck2">
              <i className="far fa-clock"></i> Redirect
            </label>

            <input type="checkbox" className="btn-check" id="btncheck3" autoComplete="off" checked={reporte} />
            <label className="btn btn-outline-success" htmlFor="btncheck3">
              <i className="far fa-clock"></i> Reporte
            </label>

            <input type="checkbox" className="btn-check" id="btncheck4" autoComplete="off" checked={reporteCompartidos} />
            <label className="btn btn-outline-success" htmlFor="btncheck4">
              <i className="far fa-clock"></i> Reporte Compartidos
            </label>

            <input type="checkbox" className="btn-check" id="btncheck5" autoComplete="off" checked={reporteXML} />
            <label className="btn btn-outline-success" htmlFor="btncheck5">
              <i className="far fa-clock"></i> Reporte XML
            </label>

            <input type="checkbox" className="btn-check" id="btncheck6" autoComplete="off" checked={instagram} />
            <label className="btn btn-outline-success" htmlFor="btncheck6">
              <i className="far fa-clock"></i> Instagram
            </label>
            <input type="checkbox" className="btn-check" id="btncheck7" autoComplete="off" checked={reporteOsTicket} />
            <label className="btn btn-outline-success" htmlFor="btncheck7">
              <i className="far fa-clock"></i> Reporte OS Ticket
            </label>
            <input type="checkbox" className="btn-check" id="btncheck8" autoComplete="off" checked={push} />
            <label className="btn btn-outline-success" htmlFor="btncheck8">
              <i className="far fa-clock"></i> Push
            </label>
            <input type="checkbox" className="btn-check" id="btncheck9" autoComplete="off" checked={twitter} />
            <label className="btn btn-outline-success" htmlFor="btncheck9">
              <i className="far fa-clock"></i> Twitter
            </label>
            <input type="checkbox" className="btn-check" id="btncheck10" autoComplete="off" checked={facebook} />
            <label className="btn btn-outline-success" htmlFor="btncheck10">
              <i className="far fa-clock"></i> Facebook
            </label>
          </FormGroup>
          <label>Sitios 2</label>
          <FormGroup className="opciones">
            <input type="checkbox" className="btn-check" id="btnSite1" autoComplete="off" checked={mwn} />
            <label className="btn btn-outline-success" htmlFor="btnSite1">
              <i className="far fa-clock"></i> Metro World News
            </label>
            <input type="checkbox" className="btn-check" id="btnSite2" autoComplete="off" checked={mwnchile} />
            <label className="btn btn-outline-success" htmlFor="btnSite2">
              <i className="far fa-clock"></i> Publimetro Chile
            </label>
            <input type="checkbox" className="btn-check" id="btnSite3" autoComplete="off" checked={mwnmexico} />
            <label className="btn btn-outline-success" htmlFor="btnSite3">
              <i className="far fa-clock"></i> Publimetro Mexico
            </label>
            <input type="checkbox" className="btn-check" id="btnSite4" autoComplete="off" checked={mwncolombia} />
            <label className="btn btn-outline-success" htmlFor="btnSite4">
              <i className="far fa-clock"></i> Publimetro Colombia
            </label>
            <input type="checkbox" className="btn-check" id="btnSite5" autoComplete="off" checked={mwnecuador} />
            <label className="btn btn-outline-success" htmlFor="btnSite5">
              <i className="far fa-clock"></i> Metro Ecuador
            </label>
            <input type="checkbox" className="btn-check" id="btnSite6" autoComplete="off" checked={mwnperu} />
            <label className="btn btn-outline-success" htmlFor="btnSite6">
              <i className="far fa-clock"></i> Publimetro Peru
            </label>
            <input type="checkbox" className="btn-check" id="btnSite7" autoComplete="off" checked={ferplei} />
            <label className="btn btn-outline-success" htmlFor="btnSite7">
              <i className="far fa-clock"></i> Ferplei
            </label>
            <input type="checkbox" className="btn-check" id="btnSite8" autoComplete="off" checked={fayerwayer} />
            <label className="btn btn-outline-success" htmlFor="btnSite8">
              <i className="far fa-clock"></i> FayerWayer
            </label>
            <input type="checkbox" className="btn-check" id="btnSite9" autoComplete="off" checked={nuevamujer} />
            <label className="btn btn-outline-success" htmlFor="btnSite9">
              <i className="far fa-clock"></i> Nueva Mujer
            </label>
            <input type="checkbox" className="btn-check" id="btnSite10" autoComplete="off" checked={mwnbrasil} />
            <label className="btn btn-outline-success" htmlFor="btnSite10">
              <i className="far fa-clock"></i> Metro World News Brasil
            </label>
            <input type="checkbox" className="btn-check" id="btnSite11" autoComplete="off" checked={novamulher} />
            <label className="btn btn-outline-success" htmlFor="btnSite11">
              <i className="far fa-clock"></i> Nova Mulher
            </label>
            <input type="checkbox" className="btn-check" id="btnSite12" autoComplete="off" checked={comutricolor} />
            <label className="btn btn-outline-success" htmlFor="btnSite12">
              <i className="far fa-clock"></i> ComuTricolor
            </label>
            <input type="checkbox" className="btn-check" id="btnSite13" autoComplete="off" checked={mwnpuertorico} />
            <label className="btn btn-outline-success" htmlFor="btnSite13">
              <i className="far fa-clock"></i> Metro Puerto Rico
            </label>
            <input type="checkbox" className="btn-check" id="btnSite14" autoComplete="off" checked={elcalce} />
            <label className="btn btn-outline-success" htmlFor="btnSite14">
              <i className="far fa-clock"></i> El Calce
            </label>
            <input type="checkbox" className="btn-check" id="btnSite15" autoComplete="off" checked={sabrosia} />
            <label className="btn btn-outline-success" htmlFor="btnSite15">
              <i className="far fa-clock"></i> Sabrosia
            </label>
            <input type="checkbox" className="btn-check" id="btnSite16" autoComplete="off" checked={activo} />
            <label className="btn btn-outline-success" htmlFor="btnSite16">
              <i className="far fa-clock"></i> Activo
            </label>
            <input type="checkbox" className="btn-check" id="btnSite17" autoComplete="off" checked={mwnargentina} />
            <label className="btn btn-outline-success" htmlFor="btnSite17">
              <i className="far fa-clock"></i> Metro World Newsargentina
            </label>
            <input type="checkbox" className="btn-check" id="btnSite18" autoComplete="off" checked={emisorasunidas} />
            <label className="btn btn-outline-success" htmlFor="btnSite18">
              <i className="far fa-clock"></i> Emisoras Unidas
            </label>
            <input type="checkbox" className="btn-check" id="btnSite19" autoComplete="off" checked={latronadora} />
            <label className="btn btn-outline-success" htmlFor="btnSite19">
              <i className="far fa-clock"></i> La Tronadora
            </label>
            <input type="checkbox" className="btn-check" id="btnSite20" autoComplete="off" checked={mwnguatemala} />
            <label className="btn btn-outline-success" htmlFor="btnSite20">
              <i className="far fa-clock"></i> Publinews Guatemala
            </label>
            <input type="checkbox" className="btn-check" id="btnSite21" autoComplete="off" checked={sagrosso} />
            <label className="btn btn-outline-success" htmlFor="btnSite21">
              <i className="far fa-clock"></i> Sagrosso
            </label>
            <input type="checkbox" className="btn-check" id="btnSite29" autoComplete="off" checked={showyfama} />
            <label className="btn btn-outline-success" htmlFor="btnSite29">
              <i className="far fa-clock"></i> Show y Fama
            </label>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() => {
              setModalCheck(!modalCheck);
            }}
          >
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalEdit} size="xl">
        <ModalHeader>Editar usuario</ModalHeader>
        <form onSubmit={editaUsuario}>
          <ModalBody>
            <FormGroup>
              <label>Email</label>
              <input type="email" className="form-control" readOnly={true} value={email}></input>
            </FormGroup>
            <FormGroup>
              <label>Nombre usuario</label>
              <input
                type="text"
                className="form-control"
                required="true"
                value={nombreUsuario}
                onChange={(e) => {
                  setNombreUsuario(e.target.value);
                }}
              ></input>
            </FormGroup>
            <FormGroup>
              <label>Perfil</label>
              <Select
                options={perfiles}
                required="true"
                defaultValue={perfil === 0 ? { label: "Usuario", value: 0 } : { label: "Administrador", value: 1 }}
                onChange={(e) => {
                  setPerfil(e.value);
                }}
              />
            </FormGroup>
            <hr></hr>
            <label>Permisos</label>
            <FormGroup className="opciones">
              <input
                type="checkbox"
                className="btn-check"
                id="btncheck1"
                autoComplete="off"
                checked={tags}
                onChange={(e) => {
                  setTags(!tags);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btncheck1">
                <i className="far fa-clock"></i> Tags
              </label>

              <input
                type="checkbox"
                className="btn-check"
                id="btncheck2"
                autoComplete="off"
                checked={redirect}
                onChange={() => {
                  setRedirect(!redirect);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btncheck2">
                <i className="far fa-clock"></i> Redirect
              </label>

              <input
                type="checkbox"
                className="btn-check"
                id="btncheck3"
                autoComplete="off"
                checked={reporte}
                onChange={() => {
                  setReporte(!reporte);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btncheck3">
                <i className="far fa-clock"></i> Reporte
              </label>

              <input
                type="checkbox"
                className="btn-check"
                id="btncheck4"
                autoComplete="off"
                checked={reporteCompartidos}
                onChange={() => {
                  setReporteCompartidos(!reporteCompartidos);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btncheck4">
                <i className="far fa-clock"></i> Reporte Compartidos
              </label>

              <input
                type="checkbox"
                className="btn-check"
                id="btncheck5"
                autoComplete="off"
                checked={reporteXML}
                onChange={() => {
                  setReporteXML(!reporteXML);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btncheck5">
                <i className="far fa-clock"></i> Reporte XML
              </label>

              <input
                type="checkbox"
                className="btn-check"
                id="btncheck6"
                autoComplete="off"
                checked={instagram}
                onChange={() => {
                  setInstagram(!instagram);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btncheck6">
                <i className="far fa-clock"></i> Instagram
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="btncheck7"
                autoComplete="off"
                checked={reporteOsTicket}
                onChange={() => {
                  setReporteOsTicket(!reporteOsTicket);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btncheck7">
                <i className="far fa-clock"></i> Reporte OS Ticket
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="btncheck8"
                autoComplete="off"
                checked={push}
                onChange={() => {
                  setPush(!push);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btncheck8">
                <i className="far fa-clock"></i> Push
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="btncheck9"
                autoComplete="off"
                checked={twitter}
                onChange={() => {
                  setTwitter(!twitter);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btncheck9">
                <i className="far fa-clock"></i> Twitter
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="btncheck10"
                autoComplete="off"
                checked={facebook}
                onChange={() => {
                  setFacebook(!facebook);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btncheck10">
                <i className="far fa-clock"></i> Facebook
              </label>
            </FormGroup>
            <hr></hr>
            <label>Sitios 3</label>
            <FormGroup className="opciones">
              <input
                type="checkbox"
                className="btn-check"
                id="btnSite1"
                autoComplete="off"
                checked={mwn}
                onChange={() => {
                  setMwn(!mwn);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btnSite1">
                <i className="far fa-clock"></i> Metro World News
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="btnSite2"
                autoComplete="off"
                checked={mwnchile}
                onChange={() => {
                  setMwnchile(!mwnchile);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btnSite2">
                <i className="far fa-clock"></i> Publimetro Chile
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="btnSite3"
                autoComplete="off"
                checked={mwnmexico}
                onChange={() => {
                  setMwnmexico(!mwnmexico);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btnSite3">
                <i className="far fa-clock"></i> Publimetro Mexico
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="btnSite4"
                autoComplete="off"
                checked={mwncolombia}
                onChange={() => {
                  setMwncolombia(!mwncolombia);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btnSite4">
                <i className="far fa-clock"></i> Publimetro Colombia
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="btnSite5"
                autoComplete="off"
                checked={mwnecuador}
                onChange={() => {
                  setMwnecuador(!mwnecuador);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btnSite5">
                <i className="far fa-clock"></i> Metro Ecuador
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="btnSite6"
                autoComplete="off"
                checked={mwnperu}
                onChange={() => {
                  setMwnperu(!mwnperu);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btnSite6">
                <i className="far fa-clock"></i> Publimetro Peru
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="btnSite7"
                autoComplete="off"
                checked={ferplei}
                onChange={() => {
                  setFerplei(!ferplei);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btnSite7">
                <i className="far fa-clock"></i> Ferplei
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="btnSite8"
                autoComplete="off"
                checked={fayerwayer}
                onChange={() => {
                  setFayerwayer(!fayerwayer);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btnSite8">
                <i className="far fa-clock"></i> FayerWayer
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="btnSite9"
                autoComplete="off"
                checked={nuevamujer}
                onChange={() => {
                  setNuevamujer(!nuevamujer);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btnSite9">
                <i className="far fa-clock"></i> Nueva Mujer
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="btnSite10"
                autoComplete="off"
                checked={mwnbrasil}
                onChange={() => {
                  setMwnbrasil(!mwnbrasil);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btnSite10">
                <i className="far fa-clock"></i> Metro World News Brasil
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="btnSite11"
                autoComplete="off"
                checked={novamulher}
                onChange={() => {
                  setNovamulher(!novamulher);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btnSite11">
                <i className="far fa-clock"></i> Nova Mulher
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="btnSite12"
                autoComplete="off"
                checked={comutricolor}
                onChange={() => {
                  setComutricolor(!comutricolor);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btnSite12">
                <i className="far fa-clock"></i> ComuTricolor
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="btnSite13"
                autoComplete="off"
                checked={mwnpuertorico}
                onChange={() => {
                  setMwnpuertorico(!mwnpuertorico);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btnSite13">
                <i className="far fa-clock"></i> Metro Puerto Rico
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="btnSite14"
                autoComplete="off"
                checked={elcalce}
                onChange={() => {
                  setElcalce(!elcalce);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btnSite14">
                <i className="far fa-clock"></i> El Calce
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="btnSite15"
                autoComplete="off"
                checked={sabrosia}
                onChange={() => {
                  setSabrosia(!sabrosia);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btnSite15">
                <i className="far fa-clock"></i> Sabrosia
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="btnSite16"
                autoComplete="off"
                checked={activo}
                onChange={() => {
                  setActivo(!activo);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btnSite16">
                <i className="far fa-clock"></i> Activo
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="btnSite17"
                autoComplete="off"
                checked={mwnargentina}
                onChange={() => {
                  setMwnargentina(!mwnargentina);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btnSite17">
                <i className="far fa-clock"></i> Publimetro Argentina
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="btnSite18"
                autoComplete="off"
                checked={emisorasunidas}
                onChange={() => {
                  setEmisorasunidas(!emisorasunidas);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btnSite18">
                <i className="far fa-clock"></i> Emisoras Unidas
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="btnSite19"
                autoComplete="off"
                checked={latronadora}
                onChange={() => {
                  setLatronadora(!latronadora);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btnSite19">
                <i className="far fa-clock"></i> La Tronadora
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="btnSite20"
                autoComplete="off"
                checked={mwnguatemala}
                onChange={() => {
                  setMwnguatemala(!mwnguatemala);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btnSite20">
                <i className="far fa-clock"></i> Publinews Guatemala
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="btnSite21"
                autoComplete="off"
                checked={sagrosso}
                onChange={() => {
                  setSagrosso(!sagrosso);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btnSite21">
                <i className="far fa-clock"></i> Sagrosso
              </label>

              <input
                type="checkbox"
                className="btn-check"
                id="btnSite29"
                autoComplete="off"
                checked={showyfama}
                onChange={() => {
                  setShowyFama(!showyfama);
                }}
              />
              <label className="btn btn-outline-success" htmlFor="btnSite29">
                <i className="far fa-clock"></i> Show y Fama
              </label>

            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="success" type="submit">
              Editar
            </Button>
            <Button
              color="danger"
              onClick={() => {
                setModalEdit(!modalEdit);
              }}
            >
              Cerrar
            </Button>
          </ModalFooter>
        </form>
      </Modal>
      <Modal isOpen={modalValidacion}>
        <ModalHeader>Advertencia!</ModalHeader>
        <ModalBody>Estas seguro que quieres eliminar este registro?</ModalBody>
        <ModalFooter>
          <button
            className="btn"
            onClick={() => {
              deleteUser(email);
            }}
          >
            SI
          </button>
          <button
            className="btn btn-danger"
            onClick={() => {
              setModalValidacion(false);
            }}
          >
            NO
          </button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalAlerta}>
        <ModalBody>{mensajeModalAlerta}</ModalBody>
        <ModalFooter>
          <button
            className="btn btn-primary"
            onClick={() => {
              setModalAlerta(false);
              setMensajeModalAlerta("");
            }}
          >
            OK
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
}
