import { makeInstance } from "../utils/conectionApiConfig";
import React, { useState, useEffect } from "react";
import { useTable, usePagination } from "react-table";

export default function ReporteSession({ token }) {
  const instance = makeInstance(token,'/session');

  //Table//
  const header = [
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Fecha",
      accessor: "fecha",
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: (e) =>
        e.value === "1" ? (
          <button className="btn true">
            <i className="fas fa-check"></i>Activo
          </button>
        ) : (
          <button className="btn false">
            <i class="fas fa-times"></i>Inactivo
          </button>
        ),
    },
    {
      Header: "Log",
      accessor: "message",
    },
  ];
  const [body, setBody] = useState([]);

  const GeneraTabla = () => {
    const data = React.useMemo(() => body, []);
    const columns = React.useMemo(() => header, []);
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      //   rows,
      prepareRow,
      page, // Instead of using 'rows', we'll use page,
      // which has only the rows for the active page

      // The rest of these things are super handy, too ;)
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns,
        data,
        initialState: { pageIndex: 0 },
      },
      usePagination
    );

    return (
      <>
        <table {...getTableProps()} className="table table-striped table-hover">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="pagination">
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {"<<"}
          </button>{" "}
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {"<"}
          </button>{" "}
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            {">"}
          </button>{" "}
          <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </button>{" "}
          <span className="numeroActualPage">
            Página{" "}
            <strong>
              {pageIndex + 1} de {pageOptions.length}
            </strong>{" "}
          </span>
          <span className="numeroGoPage">
            Ir a la página:{" "}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "100px" }}
            />
          </span>{" "}
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            placeholder={"Seleccione"}
          >
            {[10, 20, 50, 200, 500, 3000].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Mostrar {pageSize}
              </option>
            ))}
          </select>
        </div>
      </>
    );
  };

  const getReporteSession = () => {
    instance
      .get("/getRepoteSession")
      .then(({ data }) => {
        setBody(data.result);
      })
      .catch((err) => {
        console.log("Err -> ", err.response);
      });
  };

  useEffect(getReporteSession, []);

  return (
    <>
      <div className="container mb-5">
        <div className="row">
          <div className="col-12">
            <div className="box">
              <div className="box__header spaceBetween">
                <h3>Reporte Sesión</h3>
              </div>
              <div className="box__body">
                <div className="alert alert-success" role="alert">
                  <GeneraTabla />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
