import { makeInstance } from "../utils/conectionApiConfig";
import Swal from "sweetalert2";
import Select from "react-select";
import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useTable, usePagination } from "react-table";
import withReactContent from "sweetalert2-react-content";
import { Button, Modal, ModalBody, ModalHeader, FormGroup, ModalFooter, ButtonGroup } from "reactstrap";

export default function Facebook({ token }) {
  //Swal//
  const MySwal = withReactContent(Swal);

  // BackEnd //
  const backEnd = makeInstance(token,'facebook');

  // onLoad //
  const [sites, setSites] = useState([]);
  const [sitesWithAccount, setSitesWithAccount] = useState([]);
  const [modalAdd, setModalAdd] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalProcess, setModalProcess] = useState(false);

  const [selectedSite, setSelectedSite] = useState(null);

  // Facebook Object //
  const [idAccount, setIdAccount] = useState(null);
  const [site, setSite] = useState(null);
  const [detailSite, setDetailSite] = useState(null);
  const [accountName, setAccountName] = useState(null);
  const [idFacebookPage, setIdFacebookPage] = useState(null);
  const [accessToken, setAccessToken] = useState(null);

  // Job Object //
  const [idJob, setIdJob] = useState(null);
  const [tipoContenido, setTipoContenido] = useState([]);
  const [contentType, setContentType] = useState(null);
  const [contentTypeDescription, setContentTypeDescription] = useState(null);
  const [tratamiento, setTratamiento] = useState([]);
  const [treatment, setTreatment] = useState(null);
  const [treatmentDescription, setTreatmentDescription] = useState(null);
  const [programation, setProgramation] = useState(false);
  const [schedule, setSchedule] = useState([]);
  const [scheduleState, setScheduleState] = useState([]);

  const [categories, setCategories] = useState([]);
  const [checkedState, setCheckedState] = useState([]);

  // Table //
  const header = [
    {
      Header: "Nombre",
      accessor: "accountName",
    },
    {
      Header: "Tipo Contenido",
      accessor: "actionName",
      Cell: ({ value }) => (value == null ? "Sin configurar" : value),
    },
    {
      Header: "Tratamiento",
      accessor: "description",
      Cell: ({ value }) => (value == null ? "Sin configurar" : value),
    },
    {
      Header: "Acciones",
      accessor: "idAccount",
      Cell: ({ value }) => (
        <div className="buttonGroup">
          <button
            className="btn see"
            size="sm"
            onClick={() => {
              getJobDetail(value);
            }}
          >
            <i className="fas fa-pencil-alt"></i>
            Configurar
          </button>
          <button
            className="btn edit"
            size="sm"
            onClick={() => {
              getAccountDetail(value);
            }}
          >
            <i className="fas fa-pencil-alt"></i>
            Editar
          </button>
          <button
            className="btn danger"
            size="sm"
            onClick={() => {
              Swal.fire({
                title: "Estas seguro",
                text: `Esta seguro que quiere eliminar la Cuenta?`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Eliminar Cuenta",
              }).then((result) => {
                if (result.isConfirmed) {
                  deleteAccount(value);
                }
              });
            }}
          >
            <i className="fas fa-pencil-alt"></i>
            Eliminar
          </button>
        </div>
      ),
    },
  ];
  const [body, setBody] = useState([]);

  const getSites = () => {
    backEnd
      .get("getSites")
      .then(({ data }) => {
        setSites(data);
      })
      .catch((err) => {
        console.log("Error en la obtencion de Sitios", err.response);
      });
  };

  const getSitesWithAccount = () => {
    backEnd
      .get("getSitesWithAccount")
      .then(({ data }) => {
        setSitesWithAccount(data);
      })
      .catch((err) => {
        console.log("Error en la obtencion de Sitios con Cuentas", err.response);
      });
  };

  const getTreatment = () => {
    backEnd
      .get("getTreatment")
      .then(({ data }) => {
        setTratamiento(data);
      })
      .catch((err) => {
        console.log("Error en la obtencion de Tratamientos", err.response);
      });
  };

  const getContentType = () => {
    backEnd
      .get("getContentType")
      .then(({ data }) => {
        setTipoContenido(data);
      })
      .catch((err) => {
        console.log("Error en la obtencion de tipos de contenido");
      });
  };

  useEffect(() => {
    getSites();
    getSitesWithAccount();
    getTreatment();
    getContentType();
  }, []);

  const GeneraTabla = () => {
    const data = React.useMemo(() => body, []);
    const columns = React.useMemo(() => header, []);

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      // rows,
      prepareRow,
      page, // Instead of using 'rows', we'll use page,
      // which has only the rows for the active page

      // The rest of these things are super handy, too ;)
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns,
        data,
        initialState: { pageIndex: 0 },
      },
      usePagination
    );

    return (
      <>
        <table {...getTableProps()} className="table table-striped table-hover">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="pagination">
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {"<<"}
          </button>{" "}
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {"<"}
          </button>{" "}
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            {">"}
          </button>{" "}
          <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            {">>"}
          </button>{" "}
          <span className="numeroActualPage">
            Página{" "}
            <strong>
              {pageIndex + 1} de {pageOptions.length}
            </strong>{" "}
          </span>
          <span className="numeroGoPage">
            Ir a la página:{" "}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "100px" }}
            />
          </span>{" "}
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            placeholder={"Seleccione"}
          >
            {[10, 20, 50, 200, 500, 3000].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Mostrar {pageSize}
              </option>
            ))}
          </select>
        </div>
      </>
    );
  };

  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid #279655",
      "&:hover": {
        border: "1px solid #279655!important",
      },
      boxShadow: "none",
    }),
  };

  // Funciones //

  const reloadAccounts = () => {
    getAccounts(selectedSite);
    getSitesWithAccount();
  };

  const getAccounts = (idSite) => {
    backEnd
      .get("getAccounts/" + idSite)
      .then(({ data }) => {
        setBody(data);
      })
      .catch((err) => {
        console.log("Error en la obtencion de cuentas", err.response);
      });
  };

  const addAccount = (e) => {
    e.preventDefault();
    let data = { idSite: site, accountName: accountName, idFacebookPage: idFacebookPage, accountAccessToken: accessToken, accountStatus: 1 };
    backEnd
      .post("addFacebookAccount", data)
      .then(({ data }) => {
        Swal.fire(data, "", "success");
        setModalAdd(false);
      })
      .catch((err) => {
        console.log(`Error en el ingreso de usuario`, err.response);
        Swal.fire(err.response.data, "", "error");
      })
      .then(() => {
        reloadAccounts();
      });
  };

  const getJobDetail = (idAccount) => {
    backEnd
      .get("getJobDetail/" + idAccount)
      .then(({ data }) => {
        setIdJob(data.idJobQueue);
        setIdAccount(data.idAccount);
        setAccountName(data.accountName);
        setContentType(data.idtipoContenido);
        setContentTypeDescription(data.description);
        setTreatment(data.idAction);
        setTreatmentDescription(data.actionName);
        setProgramation(data.programado);
        procesaCategorias(data.categories);
        procesaAgenda(data.schedule);
      })
      .catch((err) => {
        console.log("Error con la obtencion de Job", err.response);
      })
      .then(() => {
        setModalProcess(true);
      });
  };

  const getAccountDetail = (idAccount) => {
    backEnd
      .get("getAccountDetail/" + idAccount)
      .then(({ data }) => {
        setIdAccount(data.idAccount);
        setSite(data.idSite);
        setDetailSite(data.description);
        setAccountName(data.accountName);
        setIdFacebookPage(data.idFacebookPage);
        setAccessToken(data.accountAccessToken);
      })
      .catch((err) => {
        console.log("Error en la obtencion del detalle de cuenta", err.response);
      })
      .then(() => {
        setModalEdit(true);
      });
  };

  const categoryUpdate = (position, category, checked) => {
    let update = { idJob: idJob, nameCategory: category, update: { status: checked ? 1 : 0 } };
    backEnd
      .post("updateJobDetail", update)
      .then(() => {
        const updatedCheckedState = checkedState.map((item, index) => (index === position ? !item : item));
        setCheckedState(updatedCheckedState);
      })
      .catch((err) => {
        console.log("Error en la actualizacion de categoria", err.response);
      });
  };

  const procesaCategorias = (input) => {
    setCategories(input);
    let result = [];
    input.map((item) => {
      result.push(item.status === 1 ? true : false);
    });
    setCheckedState(result);
  };

  const procesaAgenda = (input) => {
    setSchedule(input);
    let result = [];
    input.map((item) => {
      result.push(item.status == true ? 1 : 0);
    });
    setScheduleState(result);
  };

  const scheduleUpdate = (position, day, checked) => {
    let update = { idJob: idJob, day: day, checked: checked ? 1 : 0 };
    backEnd
      .post("updateSchedule", update)
      .then(() => {
        const updatedCheckedState = scheduleState.map((item, index) => (index === position ? !item : item));
        setScheduleState(updatedCheckedState);
      })
      .catch((err) => {
        console.log("Error en la actualizacion de Agenda", err.response);
      });
  };

  const updateContentType = (input) => {
    let data = { idJobQueue: idJob, update: { idtipoContenido: input } };
    backEnd
      .post("updateJob", data)
      .then(() => {
        reloadAccounts();
      })
      .catch((err) => {
        console.log("Error en la actualizacion de tipo contenido", err.response);
        reloadAccounts();
      });
  };

  const updateTreatment = (input) => {
    setTreatment(input);
    let data = { idJobQueue: idJob, update: { idAction: input } };
    backEnd
      .post("updateJob", data)
      .then(() => {
        reloadAccounts();
      })
      .catch((err) => {
        console.log("Error en la actualizacion de Tratamiento", err.response);
        reloadAccounts();
      });
  };

  const updateProgramation = (input) => {
    setProgramation(input);
    let data = { idJobQueue: idJob, update: { programado: input } };
    backEnd
      .post("updateJob", data)
      .then(() => {
        reloadAccounts();
      })
      .catch((err) => {
        console.log("Error en la actualizacion de Tratamiento", err.response);
        reloadAccounts();
      });
  };

  const editFacebookAccount = (e) => {
    e.preventDefault();
    let data = { idAccount: idAccount, update: { accountName: accountName, idSite: site, idFacebookPage: idFacebookPage, accountAccessToken: accessToken } };
    backEnd
      .post("editFacebookAccount", data)
      .then(() => {
        Swal.fire("Cuenta Actualizada", "", "success");
        setModalEdit(false);
      })
      .catch((err) => {
        Swal.fire("Error en la actualizacion de usuario", "", "error");
        console.log("Error en la actualizacion de Cuenta de Facebook", err.response);
      })
      .then(() => {
        reloadAccounts();
      });
  };

  const deleteAccount = (idAccount) => {
    backEnd
      .delete("deleteAccount/" + idAccount)
      .then(({ data }) => {
        Swal.fire("Registro eliminado correctamente!", "", "success");
      })
      .catch((err) => {
        console.log("Error en la eliminacion de la cuenta", err.response);
        Swal.fire("Error en la eliminacion de cuenta", "", "error");
      })
      .then(() => {
        reloadAccounts();
      });
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="box">
              <div className="box__header">
                <h3>Facebook</h3>
                <button
                  onClick={() => {
                    setModalAdd(true);
                  }}
                  className="btn newUser"
                >
                  <i className="fas fa-user-plus"></i>
                  Agregar Cuenta
                </button>
              </div>
              <div className="box__body">
                <div className="form-group">
                  <Select
                    placeholder={"Seleccione un sitio"}
                    styles={style}
                    options={sitesWithAccount}
                    onChange={(seleccion) => {
                      setSelectedSite(seleccion.value);
                      getAccounts(seleccion.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mb-5">
        <div className="row">
          <div className="col-12">
            <div className="box">
              <div className="box__header">
                <h3>Resultado</h3>
              </div>
              <div className="box__body">{body.length > 0 ? <GeneraTabla /> : null}</div>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={modalAdd} size="xl">
        <form onSubmit={addAccount}>
          <ModalHeader>Agrega Cuenta de Facebook</ModalHeader>
          <ModalBody>
            <FormGroup>
              <label>Nombre de Cuenta</label>
              <input
                className="form-control"
                onChange={(name) => {
                  setAccountName(name.target.value);
                }}
              />
            </FormGroup>
            <FormGroup>
              <label>Sitio</label>
              <Select
                placeholder="Selecciona un sitio"
                styles={style}
                options={sites}
                required={true}
                onChange={(seleccion) => {
                  setSite(seleccion.value);
                }}
              />
            </FormGroup>
            <FormGroup>
              <label>Identificador de Pagina</label>
              <input
                className="form-control"
                onChange={(input) => {
                  setIdFacebookPage(input.target.value);
                }}
              />
            </FormGroup>
            <FormGroup>
              <label>Token de Usuario</label>
              <input
                className="form-control"
                onChange={(input) => {
                  setAccessToken(input.target.value);
                }}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <button className="btn btn-success" type="submit">
                Guardar
              </button>
              <button
                className="btn btn-danger"
                type="button"
                onClick={() => {
                  setModalAdd(false);
                }}
              >
                Cerrar
              </button>
            </ButtonGroup>
          </ModalFooter>
        </form>
      </Modal>
      <Modal isOpen={modalEdit} size="xl">
        <form onSubmit={editFacebookAccount}>
          <ModalHeader>Editar Cuenta Facebook</ModalHeader>
          <ModalBody>
            <FormGroup>
              <label>Nombre de Cuenta</label>
              <input
                className="form-control"
                value={accountName}
                onChange={(name) => {
                  setAccountName(name.target.value);
                }}
              />
            </FormGroup>
            <FormGroup>
              <label>Sitio</label>
              <Select
                placeholder="Selecciona un sitio"
                styles={style}
                options={sites}
                required={true}
                defaultValue={site ? { value: site, label: detailSite } : null}
                onChange={(seleccion) => {
                  setSite(seleccion.value);
                }}
              />
            </FormGroup>
            <FormGroup>
              <label>Identificador de Pagina</label>
              <input
                className="form-control"
                value={idFacebookPage}
                onChange={(token) => {
                  setIdFacebookPage(token.target.value);
                }}
              />
            </FormGroup>
            <FormGroup>
              <label>Token de Acceso</label>
              <input
                className="form-control"
                value={accessToken}
                onChange={(input) => {
                  setAccessToken(input.target.value);
                }}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <button className="btn btn-success" type="submit">
                Guardar
              </button>
              <button
                className="btn btn-danger"
                type="button"
                onClick={() => {
                  setModalEdit(false);
                }}
              >
                Cerrar
              </button>
            </ButtonGroup>
          </ModalFooter>
        </form>
      </Modal>
      <Modal isOpen={modalProcess} size="xl">
        <ModalHeader>Configurar Cuenta</ModalHeader>
        <ModalBody>
          <FormGroup>
            <label>Nombre de Cuenta</label>
            <input className="form-control" type="text" value={accountName} readOnly={true} />
          </FormGroup>
          <FormGroup>
            <label>Tipo Contenido</label>
            <Select
              placeholder="Selecciona el tipo de Contenido"
              styles={style}
              options={tipoContenido}
              defaultValue={contentType ? { value: contentType, label: contentTypeDescription } : null}
              onChange={(seleccion) => {
                updateContentType(seleccion.value);
              }}
            />
          </FormGroup>
          <FormGroup>
            <label>Programacion</label>
            <Select
              styles={style}
              defaultValue={programation ? { value: 1, label: "Personalizado" } : { value: 0, label: "Todos los Dias" }}
              options={[
                { value: 0, label: "Todos los Dias" },
                { value: 1, label: "Personalizado" },
              ]}
              onChange={(seleccion) => {
                updateProgramation(seleccion.value);
              }}
            />
          </FormGroup>
          <FormGroup>
            <div hidden={!programation == 1 ? true : false}>
              <label>Detalle</label>
              <div className="row">
                <div className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                  {schedule.map((day, index) => (
                    <>
                      <input
                        type="checkbox"
                        className="btn-check"
                        id={"btncheckweek" + index}
                        checked={scheduleState[index]}
                        value={day.dia}
                        onChange={({ target }) => {
                          scheduleUpdate(index, target.value, target.checked);
                        }}
                      />
                      <label className="btn btn-outline-success" htmlFor={"btncheckweek" + index}>
                        <i className="fas fa-globe"></i> {day.dia}
                      </label>
                    </>
                  ))}
                </div>
              </div>
            </div>
          </FormGroup>
          <FormGroup>
            <label>Tratamiento</label>
            <Select
              placeholder="Selecciona el tipo de Tratamiento"
              styles={style}
              options={tratamiento}
              defaultValue={treatment ? { value: treatment, label: treatmentDescription } : null}
              onChange={(seleccion) => {
                updateTreatment(seleccion.value);
              }}
            />
          </FormGroup>
          <div hidden={treatment === 3 ? false : true}>
            <label>Categorias</label>
            {categories.map((category, index) => (
              <FormGroup>
                <div className="row">
                  <div className="btn-group col-md-4" role="group" aria-label="Basic checkbox toggle button group">
                    <input
                      type="checkbox"
                      className="btn-check"
                      id={"btncheck" + index}
                      autoComplete="off"
                      value={category.category}
                      checked={checkedState[index]}
                      onChange={({ target }) => {
                        categoryUpdate(index, target.value, target.checked);
                      }}
                    />
                    <label className="btn btn-outline-success" htmlFor={"btncheck" + index}>
                      <i className="far fa-clock"></i> {category.category}
                    </label>
                  </div>
                </div>
              </FormGroup>
            ))}
          </div>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <button
              className="btn btn-danger"
              type="button"
              onClick={() => {
                setModalProcess(false);
              }}
            >
              Cerrar
            </button>
          </ButtonGroup>
        </ModalFooter>
      </Modal>
    </>
  );
}
