import axios from "axios";
import { makeInstance } from "../utils/conectionApiConfig";

import React, { useState, useEffect } from "react";
import { Button, Input, Form, FormGroup, Label } from "reactstrap";


export default function Push({ token }) {

  const [options, setOptions] = useState([
    <option key="item-0">Cargando...</option>,
  ]);

  const [optionsCountry, setOptionsCountry] = useState([
    <option key="item-country-0" value="all">Todos</option>,
    <option key="item-country-1" value="cl">Chile</option>,
    <option key="item-country-2" value="co">Colombia</option>,
    <option key="item-country-3" value="mx">Mexico</option>,
    <option key="item-country-4" value="ec">Ecuador</option>,
    <option key="item-country-5" value="pe">Peru</option>,
    <option key="item-country-6" value="ar">Argentina</option>
  ]);

  const siteByCountry = ["nuevamujer", "ferplei", "fayerwayer","sagrosso"];
  const [visibilityCountry, setVisibilityCountry] = useState(false);


  const instance = makeInstance(token,'permisos');
  useEffect(() => {
    instance.get("/getSitios").then((response) => {
      var optionsHtml = [];
      var data = response.data;

      optionsHtml.push(
        <option key={`item-0`} value="">
          - Seleccione -
        </option>
      );

      for (var i = 0; i < data.length; i++) {
        optionsHtml.push(
          <option key={`item-${i + 1}`} value={data[i]._id}>
            {data[i].name}
          </option>
        );
      }

      setOptions(optionsHtml);
    });


  }, []);

  function isValidURL(string) {
    var res = string.match(
      /(https:\/\/.)(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g
    );
    return res !== null;
  }

  function hola(e) {

    e.preventDefault();
    //e.target[0].value
    if (e.target.site.value === "") {
      alert("Falta seleccionar el sitio");
      return false;
    }

    //e.target[1].value
    if (e.target.titulo.value === "") {
      alert("Falta ingresar el titulo");
      return false;
    }

    //e.target[3].value
    if (e.target.url.value  === "") {
      alert("Falta ingresar la URL");
      return false;
    } else {
      if (!isValidURL(e.target.url.value)) {
        alert("No es una URL valida debe comenzar con https:// ");
        return false;
      }
    }

    let config = {
      headers: {
        "content-type": "application/json",
      },
    };

    let objPush = {
      target: e.target.site.value,
      titulo: e.target.titulo.value,
      cuerpo: e.target.cuerpo.value,
      url: e.target.url.value,
    }
    if(siteByCountry.includes(e.target.site.value)){
        objPush.segmentacion = e.target.country.value
    }

    console.log(objPush)

    //https://dash.metroworldnews.com/manualnoti/mandar.php
    axios
      .post(
        "https://dash.metroworldnews.com/manualnoti/mandar.php",
        //"http://localhost:8080/dast/test.php",
        // {
        //   target: e.target[0].value,
        //   titulo: e.target[1].value,
        //   cuerpo: e.target[2].value,
        //   url: e.target[3].value,
        // },
        objPush,
        config
      )
      .then((response) => {
        console.log("jano", response.data);
        // e.target[1].value = "";
        // e.target[2].value = "";
        // e.target[3].value = "";
        e.target.site.value   = "";
        e.target.titulo.value = "";
        e.target.cuerpo.value = "";
        e.target.url.value    = "";
        setVisibilityCountry(false);

        alert("Su mensaje fue enviado exitosamente.");
      });
  }

  function handleSelect(e){

    // nueva mujer
    // ferplei
    // fayerwayer
    // sagroso
    setVisibilityCountry(siteByCountry.includes(e.target.value))
  }

  return (
    <>
      <div className="container mb-5">
        <div className="row">
          <div className="col-12">
            <div className="box">
              <div className="box__header spaceBetween">
                <h3>Notificaciones Push Instantanea</h3>
              </div>
              <div className="box__body">
                <Form onSubmit={(e) => hola(e)}>
                  <FormGroup>
                    <Label for="exampleSelect">Sitio</Label>
                    <Input id="site" name="select" type="select" onChange={handleSelect}>
                      {options}
                    </Input>
                  </FormGroup>

                  {visibilityCountry ? (<FormGroup>
                                          <Label for="exampleSelect">Pais</Label>
                                          <Input id="country" name="select" type="select">
                                            {optionsCountry}
                                          </Input>
                                        </FormGroup>) :
                                       ('')}
                  <FormGroup>
                    <Label for="titulo">Titulo</Label>
                    <Input
                      id="titulo"
                      name="titulo"
                      placeholder="Titulo de la push"
                      type="text"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="cuerpo">Cuerpo</Label>
                    <Input
                      id="cuerpo"
                      name="cuerpo"
                      placeholder="Cuerpo de la push"
                      type="text"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="url">URL</Label>
                    <Input
                      id="url"
                      name="url"
                      placeholder="EJEMPLO: https://www.metroworldnews.com/soy-una-publicacion/"
                      type="text"
                    />
                  </FormGroup>

                  <Button>Enviar Push</Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
