import { makeInstance } from "../utils/conectionApiConfig";
import Select from "react-select";
import DatePicker from "react-datepicker";
import React, { useState, useEffect } from "react";
import { useTable, useSortBy,usePagination} from "react-table";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import "react-datepicker/dist/react-datepicker.css";

export default function Reportes({ token }) {
  const [sitio, setSitio] = useState(null);
  const [categoria, setCategoria] = useState([]);
  const [autor, setAutor] = useState(null);
  const [contenidoOriginal, setContenidoOriginal] = useState(null);

  //Datepicker
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  //Selectores
  const [showSite, setShowSite] = useState(false);
  const [showCategory, setShowCategory] = useState(false);
  const [showAutor, setShowAutor] = useState(false);
  const [showFecha, setShowFecha] = useState(false);
  const [showURL, setContentUrl] = useState(false);
  const [showHora, setShowHora] = useState(false);

  //Resultado
  const [header, setHeader] = useState([]);
  const [body, setBody] = useState([]);

  //JSON
  const [urlJson, setUrlJson] = useState(null);

  //Combos
  const [comboSitios, setComboSitio] = useState([{ value: "", label: "Todos" }]);
  const [comboSitiosStatus, setComboSitioStatus] = useState(true);
  const [comboCategoria, setComboCategoria] = useState([{ value: "", label: "Todos" }]);
  const [comboCategoriaStatus, setComboCategoriaStatus] = useState(true);
  const [comboAutor, setComboAutor] = useState([{ value: "Todos", label: "Todos" }]);
  const [comboAutorStatus, setComboAutorStatus] = useState(true);
  const comboContenido = [
    { value: "Todos", label: "Todos" },
    { value: "1", label: "Original" },
    { value: "0", label: "Clonado" },
  ];
  
  const instance = makeInstance(token);

  const obtieneReporte = async () => {
    setHeader([]);
    setBody([]);
    setUrlJson(null);
    var response = {};
    response.filtro = {
      sitio: sitio,
      categoria: categoria,
      autor: autor,
      fecha: dateRange,
      cpropio: contenidoOriginal,
    };
    response.consulta = {
      sitio: showSite,
      categoria: showCategory,
      autor: showAutor,
      url: showURL,
      fecha: showFecha,
      hora: showHora,
    };

    //console.log(response)
    instance
      .post("/reportes/getReporte", response)
      .then((data) => {
       
        setHeader(data.data.header);
        setBody(data.data.data);
        setUrlJson(data.data.query);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const GeneraTabla = () => {
    const data = React.useMemo(() => body, []);
    const columns = React.useMemo(() => header, []);

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      page, // Instead of using 'rows', we'll use page,
      // which has only the rows for the active page

      // The rest of these things are super handy, too ;)
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns,
        data,
        initialState: { pageIndex: 0 },
      },
      useSortBy,
      usePagination
      
    );

    return (
      <>
        <table {...getTableProps()} className="table table-striped table-hover">
          <thead>
            {headerGroups.map((headerGroup) => (
              
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (                 
                  
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>
                    {column.isSorted ? (column.isSortedDesc ? " ▼ " : " ▲ ") : " ▼ "}
                  </span>
                </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="pagination">
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {"<<"}
          </button>{" "}
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {"<"}
          </button>{" "}
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            {">"}
          </button>{" "}
          <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            {">>"}
          </button>{" "}
          <span className="numeroActualPage">
            Página{" "}
            <strong>
              {pageIndex + 1} de {pageOptions.length}
            </strong>{" "}
          </span>
          <span className="numeroGoPage">
            Ir a la página:{" "}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "100px" }}
            />
          </span>{" "}
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            placeholder={"Seleccione"}
          >
            {[10, 20, 50, 200, 500, 3000].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Mostrar {pageSize}
              </option>
            ))}
          </select>
        </div>
      </>
    );
  };

  const obtieneSitios = async () => {
    var result = null;
    instance
      // .post("/reportes/getSitios")
      .get("permisos/getSitios")
      .then((data) => {
        result = data.data;
      })
      .catch((err) => {
        console.log("Error en obtencion de Sitios");
        console.log(err.response);
      })
      .then(() => {
        var sitios = [];
        if (result.length > 0) {
          for (var x = 0; x < result.length; x++) {
            var elemento = result[x];
            sitios.push({ value: elemento._id, label: elemento.name });
          }
          setComboSitio(sitios);
          setComboSitioStatus(false);
        } else {
          console.log("Problemas con la obtencion de Sitios");
        }
      });
  };

  const obtieneCategoria = () => {
    var result = null;
    instance
      .post("/reportes/getCategorias")
      .then((data) => {
        result = data.data;
      })
      .catch((err) => {
        console.log(err);
      })
      .then(() => {
        if (result.length > 1) {
          setComboCategoria(result);
          setComboCategoriaStatus(false);
        } else {
          console.log("Problemas con la obtencion de Categorias");
        }
      });
  };

  const obtieneAutor = () => {
    var result = null;
    instance
      .post("/reportes/getAutores")
      .then((data) => {
        result = data.data;
      })
      .catch((err) => {
        console.log(err.response);
      })
      .then(() => {
        if (result.length > 1) {
          setComboAutor(result);
          setComboAutorStatus(false);
        } else {
          console.log("Problemas con la obtencion de Autores");
        }
      });
  };

  useEffect(() => {
    obtieneSitios();
    obtieneCategoria();
    obtieneAutor();
  }, []);

  const actualizaSitio = (selector) => {
    var data = { site: selector };
    var result = null;
    setComboCategoriaStatus(true);
    setComboAutorStatus(true);
    instance
      .post("/reportes/getCatAut", data)
      .then((data) => {
        result = data.data;
      })
      .catch((err) => {
        console.log(err.response);
      })
      .then(() => {
        setComboCategoria(result.cat);
        setComboCategoriaStatus(false);
        setComboAutor(result.aut);
        setComboAutorStatus(false);
      });
  };

  const changeShowSite = () => {
    setShowSite(!showSite);
  };

  const changeShowCategory = () => {
    setShowCategory(!showCategory);
  };

  const changeShowURL = () => {
    setContentUrl(!showURL);
  };

  const changeShowAuthor = () => {
    setShowAutor(!showAutor);
  };

  const changeShowDate = () => {
    setShowFecha(!showFecha);
  };

  const changeShowTime = () => {
    setShowHora(!showHora);
  };

  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid #279655",
      "&:hover": {
        border: "1px solid #279655!important",
      },
      boxShadow: "none",
    }),
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="box">
              <div className="box__header">
                <h3>Reportes</h3>
              </div>
              <div className="box__body">
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-3">
                      <Select
                        value={sitio ? comboSitios.value : ""}
                        options={comboSitios}
                        onChange={(sitio) => {
                          setSitio(sitio.value);
                          setCategoria([]);
                          setAutor(null);
                          actualizaSitio(sitio.value);
                        }}
                        isDisabled={comboSitiosStatus}
                        isSearchable={false}
                        placeholder={"Seleccione un sitio"}
                        styles={style}
                      />
                    </div>
                    <div className="col-md-3">
                      <Select
                        closeMenuOnSelect={false}
                        isMulti
                        options={comboCategoria}
                        onChange={(categoria) => {
                          setCategoria(categoria);
                        }}
                        isDisabled={comboCategoriaStatus}
                        isSearchable={false}
                        placeholder={"Seleccione una categoría"}
                        styles={style}
                      />
                    </div>
                    <div className="col-md-3">
                      <Select
                        value={autor ? comboCategoria.value : ""}
                        options={comboAutor}
                        onChange={(autor) => {
                          setAutor(autor.value);
                        }}
                        isDisabled={comboAutorStatus}
                        isSearchable={false}
                        placeholder={"Seleccione una autor"}
                        styles={style}
                      />
                    </div>
                    <div className="col-md-3">
                      <DatePicker
                        className="form-control"
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={(update) => {
                          setDateRange(update);
                        }}
                        isClearable={true}
                        placeholderText="Seleccione fecha de publicación"
                        styles={style}
                      />
                    </div>
                  </div>
                  <br />
                  <div className="row justify-content-md-center">
                    <div className="col-md-3 col-offset-4">
                      <Select
                        value={contenidoOriginal ? comboContenido.value : ""}
                        options={comboContenido}
                        onChange={(contenidoOriginal) => {
                          setContenidoOriginal(contenidoOriginal.value);
                        }}
                        isSearchable={false}
                        placeholder={"Seleccione tipo de contenido"}
                        styles={style}
                      />
                    </div>
                  </div>
                  <br />
                  <h5>Seleccione encabezado de la tabla a mostrar</h5>
                  <div className="row">
                    <div className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                      <input type="checkbox" className="btn-check" id="btncheck1" checked={showSite} onChange={changeShowSite} />
                      <label className="btn btn-outline-success" htmlFor="btncheck1">
                        <i className="fas fa-globe"></i> Sito
                      </label>

                      <input type="checkbox" className="btn-check" id="btncheck2" autoComplete="off" checked={showCategory} onChange={changeShowCategory} />
                      <label className="btn btn-outline-success" htmlFor="btncheck2">
                        <i className="fas fa-tags"></i> Categoría
                      </label>

                      <input type="checkbox" className="btn-check" id="btncheck3" autoComplete="off" checked={showAutor} onChange={changeShowAuthor} />
                      <label className="btn btn-outline-success" htmlFor="btncheck3">
                        <i className="fas fa-user-tie"></i> Autor
                      </label>

                      <input type="checkbox" className="btn-check" id="btncheck4" autoComplete="off" checked={showFecha} onChange={changeShowDate} />
                      <label className="btn btn-outline-success" htmlFor="btncheck4">
                        <i className="far fa-calendar-times"></i> Fecha
                      </label>

                      <input type="checkbox" className="btn-check" id="btncheck5" autoComplete="off" checked={showHora} onChange={changeShowTime} />
                      <label className="btn btn-outline-success" htmlFor="btncheck5">
                        <i className="far fa-clock"></i> Hora
                      </label>

                      <input type="checkbox" className="btn-check" id="btncheck6" autoComplete="off" checked={showURL} onChange={changeShowURL} />
                      <label className="btn btn-outline-success" htmlFor="btncheck6">
                        <i className="fas fa-tags"></i> URL
                      </label>

                    </div>
                  </div>
                  <div className="col-12 text-center my-3">
                    <button className="btn me-md-2" onClick={obtieneReporte}>
                      Enviar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mb-5">
        <div className="row">
          <div className="col-12">
            <div className="box">
              <div className="box__header">
                <h3>Resultado</h3>
                <OverlayTrigger
                  delay={{ hide: 1000 }}
                  overlay={(props) => (
                    <Tooltip {...props} className="hola">
                      Click para copiar URL al portapapeles
                    </Tooltip>
                  )}
                  placement="top"
                  // trigger="click"
                >
                  <input
                    type="text"
                    readOnly="true"
                    value={urlJson ? urlJson : ""}
                    className="copiar"
                    onClick={() => {
                      navigator.clipboard.writeText(urlJson);
                    }}
                  />
                </OverlayTrigger>
              </div>
              <div className="box__body">
                <div className="alert alert-success" role="alert">
                  <GeneraTabla />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
