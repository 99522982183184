import { makeInstance } from "../utils/conectionApiConfig";
// import CheckLogin from "../hooks/checkLogin";
import React, { useState } from "react";
import { InputGroup, FormControl } from "react-bootstrap";

export default function Redirect({ token }) {
  const [message, setMessage] = useState("");
  const [fromUrl, setFromUrl] = useState("");
  const [toUrl, setToUrl] = useState("");


  const instance = makeInstance(token,'redirect');

  const editFromUrl = (e) => {
    setFromUrl(e.target.value);
  };

  const editToUrl = (e) => {
    setToUrl(e.target.value);
  };

  const procesaTexto = async () => {
    setMessage("");
    let urls = { url: fromUrl, to: toUrl };
    var { data } = await instance.post("/sendRedirect", urls);

    let jsonPretty = JSON.stringify(data, undefined, 4);

    setMessage(jsonPretty);
  };

  return (
    <>
      <div className="container mt-5"></div>

      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="box">
              <div className="box__header">
                <h3>Ingrese Url</h3>
              </div>
              <div className="box__body">
                <div className="input-group">
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="to_url">Url De</InputGroup.Text>
                    <FormControl
                      placeholder="https://www.metroecuador.com.ec/2021/09/27/cinco-tips-para-alejar-el-miedo-del-regreso-a-clases-presenciales-1/"
                      aria-label="Default"
                      aria-describedby="to_url"
                      onChange={editToUrl}
                    />
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="from_url">Url Hasta</InputGroup.Text>
                    <FormControl
                      placeholder="https://www.metroecuador.com.ec/2021/09/27/cinco-tips-para-alejar-el-miedo-del-regreso-a-clases-presenciales/"
                      aria-label="Default"
                      aria-describedby="from_url"
                      onChange={editFromUrl}
                    />
                  </InputGroup>

                  {/* <textarea
                                placeholder="Ingresa Tags"
                                style={{ width: 1080, height: 100 }}
                                onChange={editRegistro}
                                className="form-control"
                            /> */}
                  <div className="col-12 text-center my-3">
                    {/* <button className="btn me-md-2" disabled={ listadoTags ? false : true } onClick={procesaTexto}>Enviar</button> */}
                    <button className="btn me-md-2" onClick={procesaTexto}>
                      Enviar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mb-5">
        <div className="row">
          <div className="col-12">
            <div className="box">
              <div className="box__header">
                <h3>Log</h3>
              </div>

              <div className="box__body">
                <div className="alert alert-success" role="alert">
                  <pre>{message ? message : ""}</pre>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
