import { makeInstance } from "../utils/conectionApiConfig";
import download from "downloadjs";
import React, { useState, useRef } from "react";
import { FaFileCsv } from "@react-icons/all-files/fa/FaFileCsv";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaTelegramPlane } from "@react-icons/all-files/fa/FaTelegramPlane";

export default function CargaTags({ token }) {
  const scrollBottom = useRef();
  const [log, setLog] = useState("");
  const [message, setMessage] = useState("");
  const [fileLink, setFileLink] = useState("");
  const [filename, setFilename] = useState(null);
  const [listadoTags, setListadoTags] = useState("");
  const [archivoTags, setArchivoTags] = useState(null);

  const instance = makeInstance(token,'tags');

  var total = 0;
  var error = 0;
  var registrosMigrados = 0;
  var registrosActualizados = 0;
  var reporteSalida = [];
  var resultado = "";

  const onSubmit = () => {
    setMessage("");
    setLog("");
    setFileLink("");
    const formData = new FormData();
    formData.append("archivoTags", archivoTags);
    formData.append("fileName", filename);
    instance
      .post("/cargaArchivo", formData)
      .then(async (res) => {
        var arreglo = res.data.data;
        total = arreglo.length;
        for (var x = 0; x < arreglo.length; x++) {
          var registro = arreglo[x];
          var { data } = await instance.post("/procesaTags", registro);
          procesaRespuesta(total, x, data);
          scrollBottom.current.scrollTop = scrollBottom.current.scrollHeight;
        }
      })
      .catch((err) => {
        console.log(err.response);
      })
      .then(() => {
        instance
          .post("/reporteCarga", reporteSalida)
          .then(async (res_) => {
            setFileLink(res_.data.data);
          })
          .catch((err_) => {
            console.log(err_.response);
          });
      });
  };

  const procesaRespuesta = (
    registrosTotales,
    registrosProcesados,
    respuestaServidor
  ) => {
    switch (respuestaServidor.result.status) {
      case 0:
        error++;
        break;
      case 1:
        registrosMigrados++;
        break;
      case 2:
        registrosActualizados++;
        break;
      case 3:
        error++;
        break;
      case 4:
        error++;
        break;
      case 5:
        error++;
        break;
      default:
        break;
    }
    var message = `"Registros Totales" : ${registrosTotales}, "Registros procesados" : ${
      registrosProcesados + 1
    }, "Registros Insertados" : ${registrosMigrados}, Registros Actualizados: ${registrosActualizados}, "Errores" : ${error}`;
    setMessage(message);
    resultado =
      resultado +
      `"Tag":"${respuestaServidor.result.tag}","Status":"${respuestaServidor.result.status}","Mensaje":"${respuestaServidor.result.message}"\n`;
    setLog(resultado);
    reporteSalida.push(
      JSON.parse(
        `{"Tag":"${respuestaServidor.result.tag}","Status":"${respuestaServidor.result.status}","Mensaje":"${respuestaServidor.result.message}"\n}`
      )
    );
  };

  const saveFile = async (e) => {
    setArchivoTags(e.target.files[0]);
    setFilename(e.target.files[0].name);
  };

  const descargaReporte = async () => {
    var dataForm = { data: fileLink };
    instance
      .post("/descargaReporte", dataForm)
      .then(async (resp) => {
        download(resp.data, fileLink);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const descargaEjemplo = async () => {
    instance
      .get("/descargaEjemplo")
      .then(async (resp) => {
        download(resp.data, "formato_base.csv");
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const editRegistro = (e) => {
    setListadoTags(e.target.value);
  };

  const procesaTexto = async () => {
    setMessage("");
    setLog("");
    setFileLink("");
    var arreglo = listadoTags.split("\n");
    var validacion = false;
    total = arreglo.length;
    for (var x = 0; x < total; x++) {
      if (arreglo[x] !== "") {
        var registro = { name: arreglo[x] };
        var { data } = await instance.post("/procesaTags", registro);
        procesaRespuesta(total, x, data);
        validacion = true;
      }
    }
    if (validacion) {
      instance
        .post("/reporteCarga", reporteSalida)
        .then(async (res_) => {
          setFileLink(res_.data.data);
        })
        .catch((err_) => {
          console.log(err_.response);
        });
    }
  };

  return (
    <>
      <div className="container mt-5">
        <div className="row">
          <div className="col-12">
            <div className="box">
              <div className="box__header box__header-d-flex">
                <h3>Carga por medio de CSV</h3>
                <OverlayTrigger
                  delay={{ hide: 450, show: 300 }}
                  overlay={(props) => (
                    <Tooltip {...props}>Descargue A. de ejemplo</Tooltip>
                  )}
                  placement="top"
                >
                  <Button className="boton" onClick={descargaEjemplo}>
                    <i className="fas fa-arrow-down"></i> Ejemplo
                  </Button>
                </OverlayTrigger>
              </div>
              <div className="box__body">
                <div className="input-group col align-self-center">
                  <input
                    type="file"
                    className="form-control"
                    onChange={saveFile}
                  />
                  <button
                    className="input-group-text"
                    disabled={filename ? false : true}
                    onClick={onSubmit}
                  >
                    Enviar <FaTelegramPlane />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="box">
              <div className="box__header">
                <h3>Carga por medio texto separados por saltos de Linea</h3>
              </div>
              <div className="box__body">
                <div className="input-group">
                  <textarea
                    placeholder="Ingresa Tags"
                    style={{ width: 1080, height: 100 }}
                    onChange={editRegistro}
                    className="form-control"
                  />
                  <div className="col-12 text-center my-3">
                    <button
                      className="btn me-md-2"
                      disabled={listadoTags ? false : true}
                      onClick={procesaTexto}
                    >
                      Enviar <FaTelegramPlane />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mb-5">
        <div className="row">
          <div className="col-12">
            <div className="box">
              <div className="box__header">
                <h3>Reporte de Salida</h3>
              </div>

              <div className="box__body">
                <div className="alert alert-success" role="alert">
                  <span>{message ? message : ""}</span>
                </div>
                <div className="row align-items-start">
                  <div className="input-group">
                    <textarea
                      ref={scrollBottom ? scrollBottom : ""}
                      value={log ? log : ""}
                      style={{ width: "100%", height: 250 }}
                      placeholder="Log de Cargas"
                      readOnly
                      className="form-control"
                    />
                    <div className="col-12 text-center my-3">
                      <button
                        className="btn btn-success btn-sm"
                        disabled={fileLink ? false : true}
                        onClick={descargaReporte}
                      >
                        Descarga Reporte <FaFileCsv />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
