import axios from "axios";
import { makeInstance } from "../utils/conectionApiConfig";

import React, { useState, useEffect } from "react";
import { Button, Input, Form, FormGroup, Label, Table } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";



export default function Push_programada({ token }) {
  const [options, setOptions] = useState([
    <option key="item-0">Cargando...</option>,
  ]);
  const [optionsCountry, setOptionsCountry] = useState([
    <option key="item-country-0" value="all">Todos</option>,
    <option key="item-country-1" value="cl">Chile</option>,
    <option key="item-country-2" value="co">Colombia</option>,
    <option key="item-country-3" value="mx">Mexico</option>,
    <option key="item-country-4" value="ec">Ecuador</option>,
    <option key="item-country-5" value="pe">Peru</option>,
    <option key="item-country-6" value="ar">Argentina</option>
  ]);

  const siteByCountry = ["nuevamujer", "ferplei", "fayerwayer","sabrosia"];
  const [visibilityCountry, setVisibilityCountry] = useState(false);


  const [dateRange, setDateRange] = useState(new Date(Date.now() + (5 * 60 * 1000)));
  const [dataSite, setDataSite] = useState(<tr><td colSpan="6">...Seleccione Sitio...</td></tr>);
  const [dataAgenda, setDataAgenda] = useState([]);
  const [idAgenda, setIdAgenda] = useState(0);

  const config = { headers: { "content-type": "application/json", }, };

  const instance = makeInstance(token,'permisos');

  useEffect(() => {
    instance.get("/getSitios").then((response) => {
      var optionsHtml = [];
      var data = response.data;

      optionsHtml.push(
        <option key={`item-0`} value="">
          - Seleccione -
        </option>
      );

      for (var i = 0; i < data.length; i++) {
        optionsHtml.push(
          <option key={`item-${i + 1}`} value={data[i]._id}>
            {data[i].name}
          </option>
        );
      }

      setOptions(optionsHtml);




    });
  }, [dataAgenda]);

  function isValidURL(string) {
    var res = string.match(
      /(https:\/\/.)(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g
    );
    return res !== null;
  }

  function enviar(e) {

    e.preventDefault();
    console.log(e.target);
    //e.target[0].value
    if (e.target.site.value === "") {
      alert("Falta seleccionar el sitio");
      return false;
    }
    //e.target[2].value
    if (e.target.titulo.value === "") {
      alert("Falta ingresar el titulo");
      return false;
    }
    //e.target[4].value
    if (e.target.url.value === "") {
      alert("Falta ingresar la URL");
      return false;
    } else {
      if (!isValidURL(e.target.url.value)) {
        alert("No es una URL valida debe comenzar con https:// ");
        return false;
      }
    }


    let objPush = {
      target: e.target.site.value,
      fecha: e.target.envio.value,
      titulo: e.target.titulo.value,
      cuerpo: e.target.cuerpo.value,
      url: e.target.url.value,
      idPush : e.target.idAgencia.value
    }
    if(siteByCountry.includes(e.target.site.value)){

        if(e.target.country.value != "all") {
          objPush.target  = e.target.site.value + "_" + e.target.country.value;
        }

    }
    console.log(objPush);
    axios
      .post(
        "https://dash.metroworldnews.com/manualnoti/agendar.php",
        //"http://localhost:8080/dast/test_agendado.php",
        // {
        //   // target: e.target[0].value,
        //   // fecha: e.target[1].value,
        //   // titulo: e.target[2].value,
        //   // cuerpo: e.target[3].value,
        //   // url: e.target[4].value,
        //   // idPush : e.target[5].value
        //
        // },
        objPush,
        config
      )
      .then((response) => {
        console.log("jano", response.data);
        // e.target[2].value = "";
        // e.target[3].value = "";
        // e.target[4].value = "";
        e.target.titulo.value = "";
        e.target.cuerpo.value = "";
        e.target.url.value    = "";


        alert("Su mensaje fue agendado exitosamente.");
        setIdAgenda(0)
        changeSite(e.target[0].value);


      });
  }


  function editarNotificacion(e){
    var idaEditar= e.target.dataset.id;

    axios
      .get(
        `https://dash.metroworldnews.com/manualnoti/agendar.php?obtenerPush=${idaEditar}`,
        config
      )
      .then((result) => {
        console.log("obtenerParaEditar",result.data);
        if(result.data.length){

          document.getElementById("titulo").value=result.data[0].titulo;
          document.getElementById("cuerpo").value=result.data[0].cuerpo;
          document.getElementById("url").value=result.data[0].url;
          document.getElementById("envio").value=result.data[0].envio;
          document.getElementById("idAgencia").value=result.data[0].id;
          setIdAgenda(result.data[0].id);

        }
      });


  }
  function eliminarNotificacion(e){

    var idEliminar= e.target.dataset.id;

    axios
      .get(
        `https://dash.metroworldnews.com/manualnoti/agendar.php?eliminarPush=${idEliminar}`,
        config
      )
      .then((result) => {
        console.log("Eliminando Push",result.data);
        if(result.data.length){

          alert(result.data);

          changeSite(document.getElementById("exampleSelect").value);

        }
      });

  }

  function limpiarCampos(){

    let d = new Date(Date.now() + (5 * 60 * 1000));
    let date = d.getFullYear() + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" +
     ("0" + d.getDate()).slice(-2) + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2)+":00";

    document.getElementById("titulo").value="";
    document.getElementById("cuerpo").value="";
    document.getElementById("url").value="";
    document.getElementById("envio").value= date ;
    document.getElementById("idAgencia").value=0;


  }

  function changeSite(e){

    const site = typeof(e.target) != "undefined" ? e.target.value : e ;
    setDataSite(<tr><td colSpan="6">Cargando...</td></tr>);

    console.log(e);
    let table = [];
    axios
      .get(
        `https://dash.metroworldnews.com/manualnoti/agendar.php?obtenerAgendados=${site}`,
        config
      )
      .then((result) => {
        console.log("resultAgendados:",result.data);
        if(result.data.length){

          result.data.forEach((item, i) => {
            table.push(<tr key={`tr_${i}`}><td>{item.site}</td><td>{item.envio}</td><td>{item.titulo}</td><td>{item.cuerpo}</td><td>{item.url}</td><td><Button color="primary" size="sm" data-id={item.id} onClick={editarNotificacion}>Editar</Button> - <Button color="danger" size="sm" data-id={item.id} onClick={eliminarNotificacion}>Borrar</Button></td></tr>);
          });

          setDataSite(table);

        }else{
          setDataSite(<tr><td colSpan="6">No hay notificaciones para mostrar...</td></tr>);
        }



      });
      setVisibilityCountry(siteByCountry.includes(site))
      limpiarCampos();
  }

  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid #279655",
      "&:hover": {
        border: "1px solid #279655!important",
      },
      boxShadow: "none",
    }),
  };


  return (
    <>
      <div className="container mb-5">
        <div className="row">
          <div className="col-12">
            <div className="box">
              <div className="box__header spaceBetween">
                <h3>Notificaciones Push Programadas</h3>
              </div>
              <div className="box__body">
                <Form onSubmit={(e) => enviar(e)}>
                  <FormGroup>
                    <Label for="exampleSelect">Sitio</Label>
                    <Input id="site" name="select" type="select" onChange={changeSite}>
                      {options}
                    </Input>
                  </FormGroup>
                  {visibilityCountry ? (<FormGroup>
                                          <Label for="exampleSelect">Pais</Label>
                                          <Input id="country" name="select" type="select">
                                            {optionsCountry}
                                          </Input>
                                        </FormGroup>) :
                                       ('')}
                  <FormGroup>
                    <Label for="exampleSelect">Hora de lanzamiento</Label>
                    <DatePicker
                      className="form-control"
                      id="envio"
                      showTimeSelect
                      selected={dateRange}
                      minDate={(new Date())}
                      onChange={(update) => {
                        setDateRange(update);
                      }}
                      dateFormat="yyyy-MM-dd H:mm:00"
                      placeholderText="Seleccione fecha de publicación"
                      styles={style}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label for="titulo">Titulo</Label>
                    <Input
                      id="titulo"
                      name="titulo"
                      placeholder="Titulo de la push"
                      type="text"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="cuerpo">Cuerpo</Label>
                    <Input
                      id="cuerpo"
                      name="cuerpo"
                      placeholder="Cuerpo de la push"
                      type="text"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="url">URL</Label>
                    <Input
                      id="url"
                      name="url"
                      placeholder="EJEMPLO: https://www.metroworldnews.com/soy-una-publicacion/"
                      type="text"
                    />
                  </FormGroup>

                  <input type="hidden" name="idAgencia" id="idAgencia" value={idAgenda} />


                  <Button>Agendar Push</Button> &nbsp;
                  <Button id="limpiarCampos" color="warning" onClick={limpiarCampos} >Limpiar Campos</Button>
                </Form>
              </div>
            </div>

            <div className="col-12">
              <div className="box">
              <Table bordered={true} striped>
                  <thead>
                      <tr>
                          <th>Sitio</th>
                          <th>Agendado</th>
                          <th>Titulo</th>
                          <th>Cuerpo</th>
                          <th>URL</th>
                          <th>Acción</th>
                      </tr>
                  </thead>
                  <tbody>

                    {dataSite}
                  </tbody>
              </Table>
              </div>
            </div>



          </div>
        </div>
      </div>
    </>
  );
}
