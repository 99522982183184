import axios from "axios";

export const makeInstance = (token,endopoint='') => {
    
    let pathApi = process.env.REACT_APP_MODE==='local' ? "http://localhost:3001/api" : "https://data.metroworldnews.com/api";
    let endPointPath='';
    if(typeof endopoint === "string"){
      endopoint.replace(/^\//,'').replace(/\/$/,'');
      if(endopoint.length>0){
        endPointPath = `/${endopoint}/`
      }
    }else{
      console.log('endpoint ingresado no es un string');
    }
    return axios.create({
      baseURL: pathApi+endPointPath,
      headers: { Authorization: token },
    });
  
}
