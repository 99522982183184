import { makeInstance } from "../utils/conectionApiConfig";
import Swal from "sweetalert2";
import Select from "react-select";
import React, { useState, useEffect } from "react";
import { useTable, usePagination } from "react-table";
import withReactContent from "sweetalert2-react-content";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup,
  ModalFooter,
  Table,
} from "reactstrap";

export default function Monitoreo({ token }) {
  const backEnd = makeInstance(token);

  //Swal//
  const MySwal = withReactContent(Swal);
  //Modal//
  const [modalRedirect, setModalRedirect] = useState(false);
  // const [modalArticleArc, setModalArticleArc] = useState(false);
  //Table//
  const header = [
    {
      Header: "URL",
      accessor: "url",
      Cell: (e) => (
        <a href={e.value} target="_blank" rel="noreferrer">
          {e.value}
        </a>
      ),
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: (e) => (
        <span className={e.value === "200" ? "VERDE" : "ROJO"}>{e.value}</span>
      ),
    },
    {
      Header: "Acciones",
      accessor: "acciones",
      Cell: ({ value }) => (
        <div className="buttonGroup">
          <button
            className="btn see"
            size="sm"
            disabled={value.status}
            onClick={() => {
              checkArticulo(value.url, value.idArticle);
            }}
          >
            <i className="fas fa-pencil-alt"></i>
            Validar
          </button>
          <button
            className="btn edit"
            size="sm"
            disabled={value.status}
            onClick={() => {
              setUrlTo("");
              setArticulosArc([]);
              getArticle(value.idArticle);
            }}
          >
            <i className="fas fa-pencil-alt"></i>
            Procesar
          </button>
          <button
            className="btn success"
            size="sm"
            disabled={value.status === 2 ? false : true}
            onClick={() => {
              editStatus(value.idArticle, 0);
            }}
          >
            Liberar
          </button>
        </div>
      ),
    },
  ];
  const [body, setBody] = useState([]);
  //Constantes//
  const [isLoading, setIsLoading] = useState(false);
  const [idArticle, setIdArticle] = useState(null);
  const [url, setUrl] = useState("");
  const [urlTo, setUrlTo] = useState("");
  const [urlFrom, setUrlFrom] = useState("");
  const [sitio, setSitio] = useState("");
  const [status, setStatus] = useState("todos");
  const [articulosArc, setArticulosArc] = useState([]);
  //Combobox//
  const [comboSitios, setComboSitios] = useState([]);
  const [comboStatus, setComboStatus] = useState([]);

  const GeneraTabla = () => {
    const data = React.useMemo(() => body, []);
    const columns = React.useMemo(() => header, []);
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      //   rows,
      prepareRow,
      page, // Instead of using 'rows', we'll use page,
      // which has only the rows for the active page

      // The rest of these things are super handy, too ;)
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns,
        data,
        initialState: { pageIndex: 0 },
      },
      usePagination
    );

    return (
      <>
        <table {...getTableProps()} className="table table-striped table-hover">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="pagination">
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {"<<"}
          </button>{" "}
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {"<"}
          </button>{" "}
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            {">"}
          </button>{" "}
          <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </button>{" "}
          <span className="numeroActualPage">
            Página{" "}
            <strong>
              {pageIndex + 1} de {pageOptions.length}
            </strong>{" "}
          </span>
          <span className="numeroGoPage">
            Ir a la página:{" "}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "100px" }}
            />
          </span>{" "}
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            placeholder={"Seleccione"}
          >
            {[10, 20, 50, 200, 500, 3000].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Mostrar {pageSize}
              </option>
            ))}
          </select>
        </div>
      </>
    );
  };

  const DetalleArticulo = () => {
    return (
      <>
        <Table bordered={true}>
          <thead>
            <tr>
              <th>Sitio</th>
              <th>Slug</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {articulosArc.map((elemento) => {
              return (
                <tr>
                  <td>{elemento.site}</td>
                  <td>{elemento.slug}</td>
                  <td>
                    <Button
                      color="primary"
                      href={`https://metroworldnews.arcpublishing.com/composer/edit/${elemento.idArc}/`}
                      target="_blank"
                    >
                      Enlace Arc
                    </Button>
                    <Button
                      color="primary"
                      href={elemento.urlSite + elemento.slug}
                      target="_blank"
                    >
                      Enlace Directo
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </>
    );
  };

  useEffect(() => {
    getSitios();
    getStatus();
  }, []);

  //Funciones//

  const getSitios = () => {
    backEnd
      .get("/permisos/getSitios_")
      .then(({ data }) => {
        var sitio = [];
        for (var x = 0; x < data.length; x++) {
          var elemento = data[x];
          sitio.push({ value: elemento._id, label: elemento.name });
        }
        setComboSitios(sitio);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const getStatus = () => {
    backEnd
      .get("/monitoreo/getStatus")
      .then(({ data }) => {
        var estados = [{ value: "todos", label: "Todos" }];
        for (var x = 0; x < data.length; x++) {
          var elemento = data[x];
          estados.push({
            value: elemento.idStatus,
            label: elemento.description,
          });
          setComboStatus(estados);
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const getArticles = () => {
    backEnd
      .get("/monitoreo/getArticles/" + sitio + "/" + status)
      .then((result) => {
        setBody(result.data);
        setModalRedirect(false);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err.response);
        setIsLoading(false);
      });
  };

  const getArticle = (id) => {
    backEnd
      .get("/monitoreo/getArticle/" + id)
      .then(({ data }) => {
        setIdArticle(id);
        setUrl(data.urlArticle);
        setUrlFrom(data.urlArticle);
      })
      .catch((err) => {
        console.log(err.response);
      })
      .then(() => {
        setModalRedirect(true);
      });
  };

  const creaRedirect = (e) => {
    e.preventDefault();
    let urls = { url: urlTo, to: urlFrom };
    backEnd
      .post("/redirect/sendRedirect", urls)
      .then(({ data }) => {
        return MySwal.fire(<p>{`${data.message}`}</p>);
      })
      .catch((err) => {
        console.log(err);
        return MySwal.fire(<p>{`Error en la creacion de el redirect`}</p>);
      })
      .then(() => {
        setModalRedirect(false);
        getArticles();
      });
  };

  const checkArticulo = async (url, id) => {
    backEnd
      .post("/monitoreo/checkUrl", { url })
      .then(({ data }) => {
        if (data.result === 200) {
          editStatus(id, 1);
        } else {
          return MySwal.fire(
            <p>{`El Status del articulo es ${data.result}`}</p>
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const editStatus = (id, status) => {
    backEnd
      .post("/monitoreo/editArticle", { idArticle: id, status: status })
      .then(({ data }) => {
        getArticles();
        return MySwal.fire(<p>{`${data.message}`}</p>);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const getArcArticle = () => {
    setIsLoading(true);
    backEnd
      .post("monitoreo/getArcArticle", { url: url })
      .then(({ data }) => {
        setArticulosArc(data);
      })
      .catch((err) => {
        console.log(err);
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="box">
              <div className="box__header">
                <h3>Reportes</h3>
              </div>
              <div className="box__body">
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-4">
                      <Select
                        placeholder={"Seleccione un sitio"}
                        options={comboSitios}
                        isSearchable={false}
                        onChange={(e) => {
                          setSitio(e.value);
                        }}
                      />
                    </div>
                    <div className="col-md-4">
                      <Select
                        placeholder={"Seleccione un Estado"}
                        options={comboStatus}
                        isSearchable={false}
                        onChange={(e) => {
                          setStatus(e.value);
                        }}
                      />
                    </div>
                    <div className="col-md-4">
                      <button
                        className="btn"
                        onClick={() => {
                          setIsLoading(true);
                          getArticles();
                        }}
                      >
                        Enviar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mb-5">
        <div className="row">
          <div className="col-12">
            <div className="box">
              <div className="box__header spaceBetween">
                <h3>Monitoreo de Errores</h3>
              </div>
              <div className="box__body">
                <div className="form-group"></div>
                <div className="alert alert-success" role="alert">
                  {isLoading ? (
                    <>
                      <div className="modales"></div>
                      <div className="loader"></div>
                    </>
                  ) : (
                    <GeneraTabla />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={modalRedirect} size="xl">
        <form onSubmit={creaRedirect}>
          <ModalHeader>Detalle de Articulo</ModalHeader>
          <ModalBody>
            <FormGroup>
              <label>URL Desde</label>
              <input
                type="text"
                className="form-control"
                required={true}
                readOnly={true}
                value={urlFrom}
              />
              <Button color="success" href={urlFrom} target="_blank">
                Ir
              </Button>
            </FormGroup>
            <FormGroup>
              <label>URL Hasta</label>
              <input
                type="url"
                className="form-control"
                required={true}
                value={urlTo}
                onChange={(e) => {
                  setUrlTo(e.target.value);
                }}
              />
            </FormGroup>
            <hr></hr>
            <label>Posibles Redirect</label>
            <FormGroup>
              <input
                type="text"
                className="form-control"
                placeholder="Ingrese la URL a Buscar"
                value={url}
                onChange={(e) => {
                  setUrl(e.target.value);
                }}
              />
              <Button
                color="success"
                onClick={() => {
                  getArcArticle();
                }}
              >
                Buscar
              </Button>
            </FormGroup>
            <DetalleArticulo />
          </ModalBody>
          <ModalFooter>
            <Button color="success" type="submit">
              Redirect
            </Button>
            <Button
              color="primary"
              onClick={() => {
                editStatus(idArticle, 2);
              }}
            >
              Ignorar
            </Button>
            <Button
              color="danger"
              onClick={() => {
                setModalRedirect(false);
              }}
            >
              Cerrar
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
}
